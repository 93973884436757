export const MASLMarketTypes = {
  REGULATION: 1,
  FULL_MATCH: 2,
};

export const MASLMarketTypesValue = {
  1: 'REGULATION',
  2: 'FULL MATCH',
};

export const MarketTypes = [MASLMarketTypes.REGULATION, MASLMarketTypes.FULL_MATCH];

export const MASLBetTypes = {
  EVENT_WINNER: 1,
  TOTALS: 2,
};

export const MASLBetTypesValues = {
  1: 'EVENT WINNER',
  2: 'TOTALS',
};

export const MASLSubMarketTypes = {
  MONEY_LINE: 1,
  SPREAD: 2,
  TOTAL: 3,
  HOME_TOTAL: 4,
  AWAY_TOTAL: 5,
};

export const MASLSubMarketTypesValues = {
  1: 'MONEY LINE',
  2: 'SPREAD',
  3: 'TOTAL',
  4: 'HOME_TOTAL',
  5: 'AWAY_TOTAL',
};

export const SubMarkets = [
  MASLSubMarketTypes.MONEY_LINE,
  MASLSubMarketTypes.SPREAD,
  MASLSubMarketTypes.TOTAL,
  MASLSubMarketTypes.HOME_TOTAL,
  MASLSubMarketTypes.AWAY_TOTAL,
];

export const MASLTypes = {
  POSITIVE: 1,
  NEGATIVE: 2,
  OVER: 3,
  UNDER: 4,
};

export const MASLStatusNames: { [key: string]: string } = {
  LIVE: 'LIVE',
  UPCOMING: 'UPCOMING',
  COMPLETED: 'COMPLETED',
  CANCELED: 'CANCELED',
  POSTPONED: 'POSTPONED',
  NEXT: 'NEXT',
  IN_WINDOW: 'IN_WINDOW',
};

export const MASLStatusValues = {
  [MASLStatusNames.LIVE]: 1,
  [MASLStatusNames.UPCOMING]: 2,
  [MASLStatusNames.COMPLETED]: 3,
};

export const MASLRounds = {
  Q1: 'Q1',
  Q2: 'Q2',
  Q3: 'Q3',
  Q4: 'Q4',
  OT: 'OT',
  KO: 'KO',
};

export const ComputedMASLRounds = {
  Half: 'Half',
  Reg: 'Reg',
  Full: 'Full',
};

export const allRoundsDefault = {
  [MASLRounds.Q1]: null,
  [MASLRounds.Q2]: null,
  [MASLRounds.Q3]: null,
  [MASLRounds.Q4]: null,
  [ComputedMASLRounds.Half]: null,
  [ComputedMASLRounds.Reg]: null,
  [MASLRounds.OT]: null,
  [MASLRounds.KO]: null,
  [ComputedMASLRounds.Full]: null,
};

export const allRoundsOrder = [
  MASLRounds.Q1,
  MASLRounds.Q2,
  MASLRounds.Q3,
  MASLRounds.Q4,
  ComputedMASLRounds.Half,
  ComputedMASLRounds.Reg,
  MASLRounds.OT,
  MASLRounds.KO,
  ComputedMASLRounds.Full,
];
