import * as React from 'react';
import v from 'voca';
import { enqueueSnackbar } from 'notistack';

import { Box, Typography, IconButton, InputAdornment } from '@mui/material';
import cloneDeep from 'lodash.clonedeep';

import DecimalField from '@/components/DecimalField';

import {
  calcHoldPercentageOfSubMkt,
  externalMarketOddsPayload,
  getBetCommonValue,
  getFirstUnlockedOrLockedSubMkt,
  isFieldConsistentAcrossTeams,
  marketOddsPayload,
  modSubMarket,
  processBet,
} from '../marketHelper';

import { editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';
import {
  JABetTypesValues,
  JASubMarketTypes,
  JABetTypes,
  JAMarketTypes,
  JAMarketTypesValue,
} from '@/constants/jaialai';
import { getKeysWithObjectValues } from '@/helpers/misc';
import Serveicon from '@/images/icons/serving.svg';

import {
  // Styled Components
  CounterButton,
  AccordionDetails,
  GridCell,
  CellLabel,
  UnLockedIcon,
  LockedIcon,
} from '../components';
import { BACKGROUND_COLOR, BORDER_COLOR, teamTypeToKey, TEXT_COLOR } from '../constants';

import {
  MoneyLineColumn,
  SpreadColumn,
  TotalColumn,
  Outcome1Column,
  Outcome2Column,
  Outcome3Column,
  Outcome4Column,
} from '../BetColumns';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import DebugLabelDisplay from '../DebugLabelDisplay';

const BetTypeStyles = ({ betType }: { betType: number }) => {
  const commonGridTemplateAreasForEventMarket = `
  "market market market market market market market market moneyLine moneyLine moneyLine moneyLine spread spread spread spread total total total total"

  "homeTeam homeTeam homeTeam homeTeam homeTeam homeTeam homeTeamBias homeTeamBias moneyLine moneyLine moneyLine moneyLine spread spread spread spread total total total total"

  "awayTeam awayTeam awayTeam awayTeam awayTeam awayTeam awayTeamBias awayTeamBias moneyLine moneyLine moneyLine moneyLine spread spread spread spread total total total total"
  `;
  const commonGridTemplateAreasForMatchMarket = `
  "market market market market market market market market  outcome1 outcome1 outcome1 outcome1 outcome2 outcome2 outcome2 outcome2 outcome3 outcome3 outcome3 outcome3"

  "homeTeam homeTeam homeTeam homeTeam homeTeam homeTeam homeTeamBias homeTeamBias outcome1 outcome1 outcome1 outcome1 outcome2 outcome2 outcome2 outcome2 outcome3 outcome3 outcome3 outcome3"

  "awayTeam awayTeam awayTeam awayTeam awayTeam awayTeam awayTeamBias awayTeamBias outcome1 outcome1 outcome1 outcome1 outcome2 outcome2 outcome2 outcome2 outcome3 outcome3 outcome3 outcome3"
`;

  const gridTemplateAreasForMatchMarketBetTypeCorrectSets = `
  "market market market market market market market market  outcome1 outcome1 outcome1 outcome2 outcome2 outcome2 outcome3 outcome3 outcome3 outcome4 outcome4 outcome4"

  "homeTeam homeTeam homeTeam homeTeam homeTeam homeTeam homeTeamBias homeTeamBias outcome1 outcome1 outcome1 outcome2 outcome2 outcome2 outcome3 outcome3 outcome3 outcome4 outcome4 outcome4"

  "awayTeam awayTeam awayTeam awayTeam awayTeam awayTeam awayTeamBias awayTeamBias outcome1 outcome1 outcome1 outcome2 outcome2 outcome2 outcome3 outcome3 outcome3 outcome4 outcome4 outcome4"
`;
  const gridTemplates = {
    [JABetTypes.EVENT_WINNER]: commonGridTemplateAreasForEventMarket,
    [JABetTypes.CORRECT_SETS]: gridTemplateAreasForMatchMarketBetTypeCorrectSets,
    [JABetTypes.TOTAL_SETS]: commonGridTemplateAreasForMatchMarket,
    [JABetTypes.MATCH_WINNER]: commonGridTemplateAreasForMatchMarket,
  };

  const defaultGridTemplateAreas = commonGridTemplateAreasForEventMarket;

  return {
    gridTemplateAreas: gridTemplates[betType] || defaultGridTemplateAreas,
  };
};

const MarketAccordionDetails = ({
  value,
  bet,
  oddType,
  toggleSubMarketLock,
  updateSubMarketTeam,
  updateSubMarketTeamKey,
  updateBias,
  oddId,
  betId,
  values,
  externalUpdateOddsOnUnlock,
  setFieldValue,
  holdPercentage,
}: {
  value: any;
  bet: any;
  oddType: number;
  toggleSubMarketLock: Function;
  updateSubMarketTeam: Function;
  updateSubMarketTeamKey: Function;
  updateBias: Function;
  disabledMarkets: { [key: string]: boolean };
  oddId: number;
  betId: number;
  setFieldValue: any;
  values: any;
  externalUpdateOddsOnUnlock: any;
  holdPercentage: number;
}) => {
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);
  const { MONEY_LINE, SPREAD, TOTAL, OUTCOME_1, OUTCOME_2, OUTCOME_3, OUTCOME_4 } =
    JASubMarketTypes;

  const unlockedBet = processBet(bet, false);
  const lockedBet = processBet(bet, true);

  const currentBet = Object.keys(unlockedBet).length > 1 ? unlockedBet : lockedBet;

  const BET_COMMON_VALUE: any = getBetCommonValue(currentBet);

  const isMarketActive = marketOddsPayload({ odds: [value] })?.every((odd) => odd?.isMarketActive);

  const isSubMarketLocked = marketOddsPayload({
    odds: [{ bets: [bet] }],
  })?.every((odd) => odd?.isSubMarketLocked);

  const commonBoxShadowStyles = {
    boxShadow: `0 0 0 1px ${isMarketActive ? BORDER_COLOR.ACTIVE : BORDER_COLOR.SUSPENDED}`,
  };

  const useBetDetails = () => {
    const homeTeamValue = BET_COMMON_VALUE?.[teamTypeToKey.homeTeam];
    const marketType = +homeTeamValue?.marketType;

    const eventNumber = homeTeamValue?.round?.eventNumber || '';
    const setName = homeTeamValue?.round?.name || '';
    const points = +homeTeamValue?.points || 1;

    return {
      homeTeamValue,
      marketType,
      eventNumber,
      setName,
      points,
    };
  };

  const betLabel = React.useMemo(() => {
    const { marketType, eventNumber, setName, points } = useBetDetails();

    if ([JAMarketTypes.SERVE, JAMarketTypes.SETS].includes(marketType)) {
      const pointNumber = marketType === JAMarketTypes.SERVE ? `, POINT ${points}` : '';
      return `MATCH ${eventNumber}, SET ${setName}${pointNumber}`;
    } else {
      return v.upperCase(JABetTypesValues[bet?.betType as keyof {}] || '');
    }
  }, [BET_COMMON_VALUE]);

  const marketNameKebabCase = React.useMemo(() => {
    const { marketType } = useBetDetails();
    return v.kebabCase(JAMarketTypesValue[marketType as keyof {}] || '');
  }, [BET_COMMON_VALUE]);

  const betNameKebabCase = React.useMemo(() => {
    const { marketType, eventNumber, setName, points } = useBetDetails();

    if ([JAMarketTypes.SERVE, JAMarketTypes.SETS].includes(marketType)) {
      const pointNumber = marketType === JAMarketTypes.SERVE ? `, POINT ${points}` : '';
      return v.kebabCase(`MATCH ${eventNumber}, SET ${setName}${pointNumber}`);
    } else {
      return v.kebabCase(JABetTypesValues[bet?.betType as keyof {}] || '');
    }
  }, [BET_COMMON_VALUE]);
  return (
    <AccordionDetails>
      <Box
        sx={{
          width: '100%',
          mb: 1,
          ...commonBoxShadowStyles,
          borderRadius: '0.25rem',
        }}
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(20, minmax(0, 1fr))',
            gridTemplateRows: 'repeat(3, minmax(0, 50px))',
            gap: '2px',
            backgroundColor: isMarketActive ? '' : BACKGROUND_COLOR.SUSPENDED,
            ...BetTypeStyles({
              betType:
                +BET_COMMON_VALUE?.homeTeam?.marketType === JAMarketTypes.SETS
                  ? JABetTypes.EVENT_WINNER
                  : +bet.betType,
            }),
          }}
        >
          {/* Bet Type Cell */}
          <GridCell sx={{ gridArea: 'market', justifyContent: 'start', alignItems: 'center' }}>
            <CellLabel
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                gap: '2px',
              }}
              component="span"
            >
              <IconButton
                id={`toggle-${marketNameKebabCase}-${betNameKebabCase}-market-lock`}
                disableRipple
                disabled={isDisabledMatch}
                onClick={(e) => {
                  e.stopPropagation();

                  const oddsCopy = cloneDeep(values?.odds || []);
                  const valueCpy = cloneDeep(value);
                  const betCpy = cloneDeep(bet);
                  const BET_COMMON_VALUE_CPY = cloneDeep(BET_COMMON_VALUE);

                  const unlockedLean = getFirstUnlockedOrLockedSubMkt(oddsCopy);

                  const currentPayload = externalMarketOddsPayload({ odds: [{ bets: [betCpy] }] });
                  const canBypassExternalOddsUpdate = ['playerLean', 'bias'].every((field) => {
                    const { isHomeTeamFieldConsistent, isAwayTeamFieldConsistent } =
                      isFieldConsistentAcrossTeams(
                        currentPayload,
                        field as keyof {},
                        field === 'playerLean' ? unlockedLean : undefined,
                      );
                    return isHomeTeamFieldConsistent && isAwayTeamFieldConsistent;
                  });

                  if (!isSubMarketLocked || canBypassExternalOddsUpdate) {
                    getKeysWithObjectValues(betCpy)?.forEach((subMarketVal) => {
                      const currentSubMarket = betCpy[subMarketVal];
                      if (!currentSubMarket) return;

                      const currentSubMktHoldPercentage =
                        calcHoldPercentageOfSubMkt(currentSubMarket);
                      const isHoldPercentageDifferent =
                        +holdPercentage !== currentSubMktHoldPercentage;

                      [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
                        if (isHoldPercentageDifferent) {
                          const teamData = currentSubMarket[team];
                          const modifiedSubMkt = modSubMarket({
                            subMarketKey: team,
                            currentValSubMarket: {
                              ...teamData,
                              isSubMarketLocked: !isSubMarketLocked,
                            },
                            newHoldPercentage: holdPercentage,
                            oldHoldPercentage: currentSubMktHoldPercentage,
                          });

                          setFieldValue(
                            `odds[${oddId}].bets[${betId}].${teamData.subMarketType}.${team}`,
                            modifiedSubMkt[team],
                            false,
                          );
                        } else {
                          updateSubMarketTeamKey({
                            subMarketType: subMarketVal,
                            teamType: team,
                            key: 'isSubMarketLocked',
                            val: !isSubMarketLocked,
                          });
                        }
                      });
                    });
                    return;
                  }

                  // External odds update is required
                  enqueueSnackbar('Unlocking Bet!');
                  const unlockedHomeTeam = unlockedLean?.[teamTypeToKey.homeTeam];
                  const unlockedAwayTeam = unlockedLean?.[teamTypeToKey.awayTeam];
                  const homeTeamMarketData = BET_COMMON_VALUE_CPY?.[teamTypeToKey.homeTeam];
                  const currentMarketType = Number(homeTeamMarketData?.marketType);
                  const currentBetType =
                    currentMarketType === JAMarketTypes.SETS
                      ? homeTeamMarketData?.betType
                      : +homeTeamMarketData?.betType;
                  const currentSubMarketType = homeTeamMarketData?.subMarketType;
                  const unlockedBias = +homeTeamMarketData?.bias || 0;

                  getKeysWithObjectValues(betCpy)?.forEach((subMarketVal) => {
                    const currentSubMarket = betCpy[subMarketVal];
                    if (!currentSubMarket) return;

                    const currentSubMktHoldPercentage =
                      calcHoldPercentageOfSubMkt(currentSubMarket);
                    const isHoldPercentageDifferent =
                      +holdPercentage !== currentSubMktHoldPercentage;
                    [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
                      if (isHoldPercentageDifferent) {
                        const teamData = valueCpy.bets[betId][subMarketVal][team];
                        const modifiedSubMkt = modSubMarket({
                          subMarketKey: team,
                          currentValSubMarket: {
                            ...teamData,
                            isSubMarketLocked: !isSubMarketLocked,
                          },
                          newHoldPercentage: holdPercentage,
                          oldHoldPercentage: currentSubMktHoldPercentage,
                        });
                        valueCpy.bets[betId][subMarketVal][team] = modifiedSubMkt[team];
                      }
                    });
                  });

                  externalUpdateOddsOnUnlock({
                    odds: [valueCpy],
                    oddId,
                    betId,
                    isHomeTeam: true,
                    marketType: currentMarketType,
                    subMarketType: currentSubMarketType,
                    betType: currentBetType,
                    bias: unlockedBias,
                    homePlayerLean: +unlockedHomeTeam?.playerLean,
                    awayPlayerLean: +unlockedAwayTeam?.playerLean,
                    isBetUnlocked: true,
                  });
                }}
              >
                {isSubMarketLocked ? <LockedIcon /> : <UnLockedIcon />}
              </IconButton>
              <Typography
                sx={{ color: TEXT_COLOR.GRAY, fontSize: '0.875rem', lineHeight: '125%' }}
                component={'span'}
              >
                {betLabel}
              </Typography>
              &nbsp; &nbsp; &nbsp;&nbsp;{' '}
              <DecimalField
                sx={{
                  ...editCellTextFieldStyle,
                  width: '14ch',
                  '& input': {
                    textAlign: 'center',
                    padding: 0,
                  },
                  '& .MuiInputBase-input': {
                    padding: '0.35rem 0.75rem',
                    fontSize: '0.812rem',
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography
                        component="span"
                        sx={{
                          color: '#CED4DA',
                          fontSize: '0.75rem',
                          fontWeight: 500,
                          lineHeight: '1.25rem',
                        }}
                      >
                        Max
                      </Typography>
                    </InputAdornment>
                  ),
                }}
                id={`${marketNameKebabCase}-${betNameKebabCase}-max-field`}
                value={BET_COMMON_VALUE?.[teamTypeToKey.homeTeam]?.max}
                onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                  getKeysWithObjectValues(bet)?.forEach((subMarketVal) => {
                    const currentSubMarket = bet[subMarketVal];
                    if (currentSubMarket) {
                      [teamTypeToKey.homeTeam, teamTypeToKey.awayTeam].forEach((team) => {
                        const currentTeam = currentSubMarket[team];
                        if (currentTeam) {
                          updateSubMarketTeamKey({
                            subMarketType: subMarketVal,
                            teamType: team,
                            key: 'max',
                            val: Math.max(+e.target.value || 0, 0),
                          });
                          updateSubMarketTeamKey({
                            subMarketType: subMarketVal,
                            teamType: team,
                            key: 'hasModifiedProbability',
                            val: true,
                          });
                        }
                      });
                    }
                  });
                }}
                disabled={isDisabledMatch || isSubMarketLocked}
              />
            </CellLabel>
          </GridCell>

          {/* Home team name Cell */}
          <GridCell
            sx={{
              gridArea: teamTypeToKey.homeTeam,
              ...commonBoxShadowStyles,
            }}
          >
            <CellLabel id={`${marketNameKebabCase}-${betNameKebabCase}-homeTeamName`}>
              {BET_COMMON_VALUE?.[teamTypeToKey.homeTeam]?.marketType &&
                ([JAMarketTypes.EVENT].includes(
                  BET_COMMON_VALUE[teamTypeToKey.homeTeam]?.marketType,
                )
                  ? BET_COMMON_VALUE[teamTypeToKey.homeTeam]?.team?.name
                  : BET_COMMON_VALUE[teamTypeToKey.homeTeam]?.athleteName)}

              {BET_COMMON_VALUE?.[teamTypeToKey.homeTeam]?.marketType &&
                [JAMarketTypes.SERVE].includes(
                  BET_COMMON_VALUE[teamTypeToKey.homeTeam]?.marketType,
                ) &&
                BET_COMMON_VALUE[teamTypeToKey.homeTeam]?.team?.isServing && (
                  <Box
                    component="img"
                    alt="winner icon"
                    src={Serveicon}
                    sx={{ ...headToHeadTableStyles.serveIconsStyle }}
                  />
                )}
            </CellLabel>
          </GridCell>

          {/* Home team bias Cell */}
          <GridCell
            sx={{
              gridArea: 'homeTeamBias',
              ...commonBoxShadowStyles,
            }}
          >
            {<DebugLabelDisplay row={BET_COMMON_VALUE?.[teamTypeToKey.homeTeam]} />}
            <CounterButton
              incrId={`${marketNameKebabCase}-${betNameKebabCase}-homeTeam-incr-bias-btn`}
              decId={`${marketNameKebabCase}-${betNameKebabCase}-homeTeam-dec-bias-btn`}
              valId={`${marketNameKebabCase}-${betNameKebabCase}-homeTeam-bias-val`}
              value={BET_COMMON_VALUE?.[teamTypeToKey.homeTeam]?.bias || 0}
              changeHandler={(val: number) => {
                updateBias(teamTypeToKey.homeTeam, val);
              }}
              bgColor="#DFDFDF"
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>

          {/* Away team name Cell */}
          <GridCell
            sx={{
              gridArea: teamTypeToKey.awayTeam,
              borderBottomLeftRadius: '0.25rem',
              ...commonBoxShadowStyles,
            }}
          >
            <CellLabel id={`${marketNameKebabCase}-${betNameKebabCase}-awayTeamName`}>
              {[JAMarketTypes.EVENT].includes(
                BET_COMMON_VALUE?.[teamTypeToKey.awayTeam]?.marketType,
              )
                ? BET_COMMON_VALUE?.[teamTypeToKey.awayTeam]?.team?.name
                : BET_COMMON_VALUE?.[teamTypeToKey.awayTeam]?.athleteName}

              {BET_COMMON_VALUE?.[teamTypeToKey.awayTeam]?.marketType &&
                [JAMarketTypes.SERVE].includes(
                  BET_COMMON_VALUE[teamTypeToKey.awayTeam]?.marketType,
                ) &&
                BET_COMMON_VALUE[teamTypeToKey.awayTeam]?.team?.isServing && (
                  <Box
                    component="img"
                    alt="winner icon"
                    src={Serveicon}
                    sx={{ ...headToHeadTableStyles.serveIconsStyle }}
                  />
                )}
            </CellLabel>
          </GridCell>

          {/* Away team bias Cell */}
          <GridCell
            sx={{
              gridArea: 'awayTeamBias',
              ...commonBoxShadowStyles,
            }}
          >
            {<DebugLabelDisplay row={BET_COMMON_VALUE?.[teamTypeToKey.awayTeam]} />}
            <CounterButton
              incrId={`${marketNameKebabCase}-${betNameKebabCase}-awayTeam-incr-bias-btn`}
              decId={`${marketNameKebabCase}-${betNameKebabCase}-awayTeam-dec-bias-btn`}
              valId={`${marketNameKebabCase}-${betNameKebabCase}-awayTeam-bias-val`}
              value={BET_COMMON_VALUE?.[teamTypeToKey.awayTeam]?.bias || 0}
              changeHandler={(val: number) => {
                updateBias(teamTypeToKey.awayTeam, val);
              }}
              bgColor="#DFDFDF"
              disabled={isDisabledMatch || isSubMarketLocked}
            />
          </GridCell>

          {/* Money Line column */}
          {!!bet[MONEY_LINE] && (
            <MoneyLineColumn
              id={`${marketNameKebabCase}-${betNameKebabCase}`}
              bet={bet}
              oddType={oddType}
              toggleSubMarketLock={toggleSubMarketLock}
              updateSubMarketTeam={updateSubMarketTeam}
              commonBoxShadowStyles={commonBoxShadowStyles}
            />
          )}

          {/* Spread column */}
          {!!bet[SPREAD] && (
            <SpreadColumn
              id={`${marketNameKebabCase}-${betNameKebabCase}`}
              bet={bet}
              oddType={oddType}
              toggleSubMarketLock={toggleSubMarketLock}
              updateSubMarketTeam={updateSubMarketTeam}
              updateSubMarketTeamKey={updateSubMarketTeamKey}
              commonBoxShadowStyles={commonBoxShadowStyles}
            />
          )}

          {!!bet[TOTAL] && (
            <TotalColumn
              id={`${marketNameKebabCase}-${betNameKebabCase}`}
              bet={bet}
              oddType={oddType}
              toggleSubMarketLock={toggleSubMarketLock}
              updateSubMarketTeam={updateSubMarketTeam}
              updateSubMarketTeamKey={updateSubMarketTeamKey}
              commonBoxShadowStyles={commonBoxShadowStyles}
            />
          )}

          {!!bet[OUTCOME_1] && (
            <Outcome1Column
              id={`${marketNameKebabCase}-${betNameKebabCase}`}
              bet={bet}
              oddType={oddType}
              toggleSubMarketLock={toggleSubMarketLock}
              updateSubMarketTeam={updateSubMarketTeam}
              updateSubMarketTeamKey={updateSubMarketTeamKey}
              commonBoxShadowStyles={commonBoxShadowStyles}
            />
          )}

          {!!bet[OUTCOME_2] && (
            <Outcome2Column
              id={`${marketNameKebabCase}-${betNameKebabCase}`}
              bet={bet}
              oddType={oddType}
              toggleSubMarketLock={toggleSubMarketLock}
              updateSubMarketTeam={updateSubMarketTeam}
              updateSubMarketTeamKey={updateSubMarketTeamKey}
              commonBoxShadowStyles={commonBoxShadowStyles}
            />
          )}

          {!!bet[OUTCOME_3] && (
            <Outcome3Column
              id={`${marketNameKebabCase}-${betNameKebabCase}`}
              bet={bet}
              oddType={oddType}
              toggleSubMarketLock={toggleSubMarketLock}
              updateSubMarketTeam={updateSubMarketTeam}
              updateSubMarketTeamKey={updateSubMarketTeamKey}
              commonBoxShadowStyles={commonBoxShadowStyles}
            />
          )}

          {!!bet[OUTCOME_4] && (
            <Outcome4Column
              id={`${marketNameKebabCase}-${betNameKebabCase}`}
              bet={bet}
              oddType={oddType}
              toggleSubMarketLock={toggleSubMarketLock}
              updateSubMarketTeam={updateSubMarketTeam}
              updateSubMarketTeamKey={updateSubMarketTeamKey}
              commonBoxShadowStyles={commonBoxShadowStyles}
            />
          )}
        </Box>
      </Box>
    </AccordionDetails>
  );
};

export default MarketAccordionDetails;
