import { IconButton } from '@mui/material';
import v from 'voca';
import * as React from 'react';

import { JASubMarketTypes, JASubMarketTypesValues } from '@/constants/jaialai';

import {
  // Styled Components
  GridCell,
  UnLockedIcon,
  LockedIcon,
  OddSubMarketLabel,
  CalculatedDecimalField,
  // Normal Components
  CommonOddField,
} from '../components';
import { teamTypeToKey } from '../constants';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import DebugLabelDisplay from '../DebugLabelDisplay';

const TotalColumn = ({
  bet,
  oddType,
  toggleSubMarketLock,
  updateSubMarketTeam,
  updateSubMarketTeamKey,
  commonBoxShadowStyles,
  id,
}: {
  bet: any;
  oddType: number;
  toggleSubMarketLock: Function;
  updateSubMarketTeam: Function;
  updateSubMarketTeamKey: Function;
  commonBoxShadowStyles: any;
  id: string;
}) => {
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);
  const subMarketTypeNameKebabCase = v.kebabCase(
    JASubMarketTypesValues[JASubMarketTypes.TOTAL as keyof {}] || '',
  );
  const isSubMarketLocked =
    bet?.[JASubMarketTypes.TOTAL]?.[teamTypeToKey.homeTeam]?.isSubMarketLocked &&
    bet?.[JASubMarketTypes.TOTAL]?.[teamTypeToKey.awayTeam]?.isSubMarketLocked;
  return (
    <GridCell
      sx={{
        gridArea: 'total',
        justifyContent: 'start',
        display: 'grid',
        gridTemplateAreas: `"lock ." "calculatedValue homeTeamTotal" "calculatedValue awayTeamTotal"`,
        gridTemplateRows: 'repeat(3, minmax(0, 50px))',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: '2px',
        borderBottomRightRadius: '0.25rem',
        ...commonBoxShadowStyles,
      }}
    >
      <GridCell
        sx={{
          gridArea: 'lock',
          boxShadow: 'none',
          justifyContent: 'flex-start',
        }}
      >
        <IconButton
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            const subMarketType = JASubMarketTypes.TOTAL;
            toggleSubMarketLock(subMarketType);
          }}
          id={`toggle-${id}-${subMarketTypeNameKebabCase}-market-lock`}
          disabled={isDisabledMatch}
        >
          {isSubMarketLocked ? <LockedIcon /> : <UnLockedIcon />}
        </IconButton>
      </GridCell>
      <GridCell
        sx={{
          gridArea: 'calculatedValue',
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <OddSubMarketLabel component="span">o</OddSubMarketLabel>
        <DebugLabelDisplay row={bet?.[JASubMarketTypes.TOTAL]?.[teamTypeToKey.homeTeam]} />
        <CalculatedDecimalField
          id={`${id}-${subMarketTypeNameKebabCase}-homeTeamCalculatedValue`}
          allowNegative
          value={bet?.[JASubMarketTypes.TOTAL]?.[teamTypeToKey.homeTeam]?.calculatedValue}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            Object.keys(teamTypeToKey).forEach((key, idx, arr) => {
              updateSubMarketTeamKey({
                subMarketType: JASubMarketTypes.TOTAL,
                teamType: teamTypeToKey[key as keyof {}],
                key: 'calculatedValue',
                val: +e.target.value || 0,
                allowExternalUpdate: arr.length - 1 === idx ? true : false,
              });
              updateSubMarketTeamKey({
                subMarketType: JASubMarketTypes.TOTAL,
                teamType: teamTypeToKey[key as keyof {}],
                key: 'hasModifiedProbability',
                val: true,
              });
            });
          }}
          InputProps={{
            readOnly: true,
          }}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
        <OddSubMarketLabel component="span">u</OddSubMarketLabel>
      </GridCell>

      <GridCell
        sx={{
          gridArea: 'homeTeamTotal',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          boxShadow: 'none',
        }}
      >
        <DebugLabelDisplay row={bet?.[JASubMarketTypes.TOTAL]?.[teamTypeToKey.homeTeam]} />
        <CommonOddField
          id={`${id}-${subMarketTypeNameKebabCase}-homeTeam-odds`}
          oddType={oddType}
          bet={bet}
          subMarketType={JASubMarketTypes.TOTAL}
          teamType={teamTypeToKey.homeTeam}
          updateSubMarketTeam={updateSubMarketTeam}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
      </GridCell>

      <GridCell
        sx={{
          gridArea: 'awayTeamTotal',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          boxShadow: 'none',
        }}
      >
        <DebugLabelDisplay row={bet?.[JASubMarketTypes.TOTAL]?.[teamTypeToKey.awayTeam]} />
        <CommonOddField
          id={`${id}-${subMarketTypeNameKebabCase}-awayTeam-odds`}
          oddType={oddType}
          bet={bet}
          subMarketType={JASubMarketTypes.TOTAL}
          teamType={teamTypeToKey.awayTeam}
          updateSubMarketTeam={updateSubMarketTeam}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
      </GridCell>
    </GridCell>
  );
};

export default TotalColumn;
