import React, { useState } from 'react';
import round from 'lodash.round';
import { useRecoilState } from 'recoil';
import MaterialTable, { Column } from '@material-table/core';

import { Box, Typography, MenuItem, Grid, Button, styled } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { oddsType } from '@/atoms/oddsType';

import SaveOddsBtn from '@/components/SaveOddsBtn';
import ResetOddsBtn from '@/components/ResetOddsBtn';
import PublishOdds from '@/components/PublishOdds';
import OddMarketToggle from '@/features/odds/OddMarketToggle';

import * as oddsHelpers from '@/helpers/odds';
import SelectWinnerIcon from '@/images/icons/table-select-winner-icon.png';

import { ODDS_PROJECTION_TYPE } from '@/constants/oddsProjectionType';
import { HeatStatus } from '@/constants/heats';

import { tableIcons } from '@/utils/TableMisc';
import { processHeatStatusBadges } from '@/helpers/events';

import { generateFightWinnerOddsColumns } from '@/features/odds/Tabs/FightWinnerTab/Columns';
import RoundDetails from '@/components/RoundDetails';
import { TYPES_OF_START_AND_END_TIMES } from '@/constants/misc';
import { ODD_MARKET_TOGGLE_TYPE } from '@/constants/oddTabs';
import ConfirmDialog from '@/components/Dialogs/ConfirmDialog';

const Container = styled(Box)(() => ({
  padding: '1.5rem 0',
  borderRadius: '0',
  boxShadow: 'none',
  ':first-of-type': {
    borderTop: '0.12rem solid #DEE2E6',
    marginTop: '1rem',
  },
}));

const HeatTitle = styled(Typography)(({ theme }) => ({
  fontSize: '1.25rem',
  fontWeight: '500',
  color: theme.palette.info.main,
  lineHeight: '1rem',
}));

const FightWinnerOddsListView = (props: any) => {
  const {
    eventId,
    sport,
    refetchOdds,
    selectedRoundId,
    selectedHeat,
    changeHeatWinners,
    handlePublishOdds,
    handleSelectWinner,
    handleVoidHeat,
    setSelectedToBeResetHeatId,
    setCurrentHeatSelection,
    handleToggleEditConfirmDialog,
    handleToggleResetDialog,
  } = props;
  const [oddType] = useRecoilState(oddsType);
  const [holdPercentage, setHoldPercentage] = useState(
    oddsHelpers.getTotalProbability(selectedHeat?.athletes),
  );
  const [showSelectWinnerDialog, setShowSelectWinnerDialog] = useState(false);
  const [selectedAthlete, setSelectedAthlete] = useState<any>(null);

  const handleToggleSelectWinnerDialog = (rowData: any = null) => {
    setShowSelectWinnerDialog(!showSelectWinnerDialog);
    setSelectedAthlete(rowData);
  };

  React.useEffect(() => {
    if (selectedHeat) {
      const totalProbability = oddsHelpers.getTotalProbability(selectedHeat?.athletes);
      setHoldPercentage(totalProbability);
    }
  }, [selectedHeat]);

  const headers: Column<any>[] = generateFightWinnerOddsColumns({
    oddType,
    holdPercentage,
    selectedHeat,
  });

  const MIN_HOLD_PERCENTAGE = React.useMemo(() => {
    if (selectedHeat && selectedHeat?.athletes.length > 0) {
      let total = 0;
      selectedHeat?.athletes?.map((data: any) => {
        total += +data?.trueProbability;
        return data;
      });
      return round(total);
    }
    return 100;
  }, [selectedHeat]);

  // Max limit value from the default hold percentage
  const MAX_PERCENTAGE_LIMIT_VALUE = 1.25;
  const MAX_ALLOWED_HOLD_PERCENTAGE_VALUE = MIN_HOLD_PERCENTAGE * MAX_PERCENTAGE_LIMIT_VALUE;

  const MAX_HOLD_PERCENTAGE = MIN_HOLD_PERCENTAGE * 2;

  const holdPercentagesOptions = React.useMemo(
    () =>
      Array(MAX_HOLD_PERCENTAGE - MIN_HOLD_PERCENTAGE + 1)
        .fill(0)
        .map((_, idx) => MIN_HOLD_PERCENTAGE + idx),
    [MIN_HOLD_PERCENTAGE, MAX_HOLD_PERCENTAGE],
  );

  return (
    <Container>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.875rem', mb: 1.5 }}>
        <HeatTitle>{`${selectedHeat?.name || 'Heat'}`}</HeatTitle>
        <Box>{processHeatStatusBadges(selectedHeat?.heatStatus, selectedHeat?.heatNo)}</Box>
        <Button
          onClick={() => handleVoidHeat(selectedHeat?.id)}
          disabled={
            !!selectedHeat?.isHeatWinnerMarketVoided ||
            selectedHeat?.heatStatus === HeatStatus.COMPLETED
          }
          variant="contained"
          disableElevation
          size="small"
          color="error"
          // sx={{ margin: '0.7rem' }}
        >
          {selectedHeat?.isHeatWinnerMarketVoided ? 'Voided' : 'Void'}
        </Button>
      </Box>
      <OddMarketToggle
        eventId={eventId}
        sport={sport}
        marketType={ODD_MARKET_TOGGLE_TYPE.IS_HEAT_WINNER_MARKET_OPEN}
        isMarketOpen={selectedHeat?.isHeatWinnerMarketOpen as boolean}
        heatId={selectedHeat?.id}
        refetchOdds={refetchOdds}
      />
      <RoundDetails
        showName={false}
        selectedRound={selectedHeat}
        typeOfStartAndEndTime={TYPES_OF_START_AND_END_TIMES.fight}
      />
      <Grid
        container
        alignItems={'center'}
        item
        xs={12}
        sx={{
          mt: 1.5,
          mb: 2,
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: { xs: 'flex-start', sm: 'space-between' },
          gap: { xs: '1rem', sm: 0 },
        }}
      >
        <Grid container alignItems={'center'} item xs={12} sm={8}>
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'info.main' }}>
            HOLD PERCENTAGE:
          </Typography>
          &nbsp;
          <Select
            id="selectHoldPercentage"
            value={holdPercentage}
            onChange={(event: SelectChangeEvent<typeof holdPercentage>) => {
              const {
                target: { value },
              } = event;
              const newHoldPercentage = Number(value);
              if (newHoldPercentage !== holdPercentage) {
                const updatedAthleteOdds = oddsHelpers.eventOddsPositionModifier({
                  eventOddsPosition: selectedHeat?.athletes,
                  newHoldPercentage,
                  oldHoldPercentage: holdPercentage,
                });
                changeHeatWinners(selectedRoundId, selectedHeat?.id, updatedAthleteOdds);
                setHoldPercentage(newHoldPercentage);
              }
            }}
            sx={{ height: 30 }}
            MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
            disabled={
              selectedHeat?.isHeatWinnerMarketVoided ||
              selectedHeat?.heatStatus === HeatStatus.COMPLETED
                ? true
                : false
            }
          >
            {React.Children.toArray(
              holdPercentagesOptions.map((percent) => (
                <MenuItem
                  value={percent}
                  disabled={
                    percent > MAX_ALLOWED_HOLD_PERCENTAGE_VALUE
                      ? // ||
                        // !canSelectHoldPercentage[percent]
                        true
                      : false
                  }
                >
                  {percent}
                </MenuItem>
              )),
            )}
          </Select>
          &nbsp;&nbsp;
          <Typography
            sx={{
              fontSize: '0.875rem',
              fontWeight: 600,
              color: 'neutral.main',
              marginLeft: '0.75rem',
            }}
          >
            MARGIN:
          </Typography>
          &nbsp;
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 600, color: 'neutral.main' }}>
            {holdPercentage - MIN_HOLD_PERCENTAGE}%
          </Typography>
          &nbsp;
        </Grid>
        <Grid
          container
          alignItems={'center'}
          item
          xs={12}
          sm={4}
          sx={{ justifyContent: { xs: 'flex-start', sm: 'flex-end' } }}
        >
          <ResetOddsBtn
            disabled={!selectedHeat?.hasBeenEdited}
            resetOddsHandler={() => {
              setSelectedToBeResetHeatId(selectedHeat?.id);
              handleToggleResetDialog();
            }}
          />
          <SaveOddsBtn
            disabled={!selectedHeat?.hasBeenEdited}
            saveOddsHandler={() => {
              setCurrentHeatSelection(selectedHeat);
              handleToggleEditConfirmDialog();
            }}
          />
        </Grid>
      </Grid>

      <Grid
        container
        // justifyContent={'flex-end'}
        alignItems={'flex-start'}
        item
        xs={12}
        sx={{ marginTop: '1rem' }}
      >
        <PublishOdds
          clientUpdatedAtDate={selectedHeat?.clientUpdatedAtDate || ''}
          traderUpdatedAtDate={selectedHeat?.traderUpdatedAtDate || ''}
          traderUpdatedAtDates={selectedHeat?.traderUpdatedAtDate || null}
          handlePublishOdds={() => {
            handlePublishOdds(ODDS_PROJECTION_TYPE.heatProjections, selectedHeat?.id);
          }}
        />
      </Grid>
      <Box sx={{ marginTop: '1rem' }}>
        <MaterialTable
          data={selectedHeat?.athletes || []}
          icons={tableIcons}
          columns={headers}
          actions={
            selectedHeat?.isHeatWinnerMarketVoided || selectedHeat?.heatWinnerAthleteId
              ? undefined
              : [
                  {
                    icon: () => (
                      <Box
                        component="img"
                        alt="select winner icon"
                        src={SelectWinnerIcon}
                        sx={{ width: '1.25rem', imageRendering: '-webkit-optimize-contrast' }}
                      />
                    ),
                    tooltip: 'Select as winner',
                    onClick: (_, rowData) => {
                      handleToggleSelectWinnerDialog(rowData);
                    },
                  },
                ]
          }
          editable={
            selectedHeat?.isHeatWinnerMarketVoided
              ? undefined
              : {
                  isEditHidden: () => !!selectedHeat?.isHeatWinnerMarketVoided,
                  isDeleteHidden: () => true,
                  onRowUpdate: (oddRow: any) => {
                    return new Promise((resolve) => {
                      setTimeout(() => {
                        const updatedAthleteOdds = oddsHelpers?.editRowFormatter(
                          { ...oddRow, hasModifiedProbability: true },
                          selectedHeat?.athletes || [],
                        );
                        changeHeatWinners(selectedRoundId, selectedHeat?.id, updatedAthleteOdds);
                        resolve('success');
                      }, 1000);
                    });
                  },
                  onRowDelete: () =>
                    new Promise(() => {
                      //
                    }),
                }
          }
          options={{
            actionsColumnIndex: -1,
            toolbar: false,
            thirdSortClick: false,
            paging: false,
            idSynonym: 'heatOddId',
          }}
        />
      </Box>
      {showSelectWinnerDialog && (
        <ConfirmDialog
          open={showSelectWinnerDialog}
          handleClose={handleToggleSelectWinnerDialog}
          handleConfirm={() => {
            handleToggleSelectWinnerDialog();
            handleSelectWinner({
              roundId: selectedRoundId,
              roundHeatId: selectedHeat?.id,
              athleteId: selectedAthlete?.id,
            });
          }}
          title={`ARE YOU SURE YOU WANT TO MAKE ${[
            selectedAthlete?.firstName,
            selectedAthlete?.middleName,
            selectedAthlete?.lastName,
          ]
            .filter(Boolean)
            .join(' ')} THE WINNER?`}
          body={'This action will select the winner.'}
        />
      )}
    </Container>
  );
};

export default FightWinnerOddsListView;
