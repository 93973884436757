import * as React from 'react';
import v from 'voca';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { MASLGameContext } from '@/contexts/maslGame.context';

import { Match } from '@/types/game';
import { EVENT_STATUS_COLORS } from '@/helpers/masl';
import { displayInPT as format } from '@/helpers/timeConverters';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import SPORT_TYPES from '@/constants/sportTypes';
import { INITIAL_MASL_VALUE } from '../..';
import { MASL_GAME_QUERY_PARAMS } from '@/constants/misc';
import keys from '@/constants/queryKeys';
import { TEXT_COLOR } from '../Markets/constants';

// 1: MATCH_STATUS_NAMES.LIVE,
// 2: MATCH_STATUS_NAMES.UPCOMING,
// 3: MATCH_STATUS_NAMES.COMPLETED,

const MatchCardMASL = React.forwardRef(
  (
    { match, gameDateId, setFieldValue }: { match: Match; gameDateId?: string; setFieldValue: any },
    ref,
  ) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const { selectedGame } = React.useContext(MASLGameContext);
    const {
      startDate,
      endDate,
      status = 0,
      statusText = '',
      homeTeam = '',
      homeTeamGoals = null,
      awayTeam = '',
      awayTeamGoals = null,
    } = match;

    return (
      <Box sx={{ display: 'inline-block' }} ref={ref} id={`match-${match?.eventNumber}-scorecard`}>
        <Paper
          sx={{
            cursor: 'pointer',
            padding: '0.6rem',
            //   display: 'flex',
            //   gap: '0.75rem',
            borderRadius: '0.375rem',
            border: `1px solid ${selectedGame === match.id ? '#86B7FE' : '#B3B3B3'}`,
            transition: 'border 0.2s, backgroundColor 0.2s',
            backgroundColor: selectedGame === match.id ? TEXT_COLOR.WHITE : '#F8F9FA',
            boxShadow: selectedGame === match.id ? '0px 4px 4px 0px rgba(0, 0, 0, 0.15)' : 'none',
            display: 'grid',
            gridTemplateAreas:
              '"matchDetails matchDetails"  "teams teams " "teams teams" "matchStatusAndTime matchStatusAndTime"',
            gap: '0.25rem',
            minWidth: '16rem',
            maxWidth: '21rem',
            marginRight: '0.8rem',
          }}
          onClick={() => {
            if (selectedGame !== match.id) {
              const redirectParams = new URLSearchParams();
              redirectParams.set(MASL_GAME_QUERY_PARAMS.matchSelected, match.id || '');
              setFieldValue('odds', INITIAL_MASL_VALUE.odds, false);
              setFieldValue('clientUpdatedAtDate', INITIAL_MASL_VALUE.clientUpdatedAtDate, false);
              setFieldValue('traderUpdatedAtDate', INITIAL_MASL_VALUE.traderUpdatedAtDate, false);
              queryClient.invalidateQueries([
                keys.games.fetchGameHeatScores,
                SPORT_TYPES.MASL,
                gameDateId,
              ]);
              queryClient.invalidateQueries([keys.odds.fetchGameOdds, SPORT_TYPES.MASL, match.id]);
              navigate(
                {
                  pathname: location.pathname,
                  search: redirectParams.toString(),
                },
                { replace: true },
              );
            }
          }}
        >
          <Box
            sx={{
              gridArea: 'matchDetails',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: 1,
            }}
          >
            <Typography
              sx={{
                fontWeight: 400,
                fontSize: '0.8125rem',
                lineHeight: '1.25rem',
              }}
            >
              {startDate
                ? `START ${format(
                    new Date(startDate),

                    'hh:mm a',
                  )} PST`
                : ''}
            </Typography>

            <Typography
              sx={{
                fontWeight: 500,
                fontSize: '0.8125rem',
                lineHeight: '1.25rem',
              }}
              variant="caption"
              component={'span'}
            >
              {`Game ${match?.eventNumber || '-'}`}
            </Typography>
          </Box>
          <Box sx={{ gridArea: 'teams', display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  color: '#212529',
                }}
                variant="caption"
                component={'span'}
              >
                {v.titleCase(homeTeam || '-')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {/* {homeTeamScores.map((scr) =>
                React.Children.toArray( */}
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: '0.9375rem',
                    backgroundColor:
                      selectedGame === match.id ? 'rgba(13, 110, 253, 0.50)' : '#E4E5E6',
                    paddingY: '0.1875rem',
                    paddingX: '0.625rem',
                    borderRadius: '0.25rem',
                    maxWidth: '1.625rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  variant="caption"
                  component={'span'}
                >
                  {homeTeamGoals || '-'}
                </Typography>

                {/* ),
             )} */}
              </Box>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: '0.875rem',
                  lineHeight: '1.25rem',
                  color: '#212529',
                }}
                variant="caption"
                component={'span'}
              >
                {v.titleCase(awayTeam || '-')}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                {/* {awayTeamScores.map((scr) =>
                React.Children.toArray( */}
                <Typography
                  sx={{
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: '0.9375rem',
                    backgroundColor:
                      selectedGame === match.id ? 'rgba(13, 110, 253, 0.50)' : '#E4E5E6',
                    paddingY: '0.1875rem',
                    paddingX: '0.625rem',
                    borderRadius: '0.25rem',
                    maxWidth: '1.625rem',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  variant="caption"
                  component={'span'}
                >
                  {awayTeamGoals || '-'}
                </Typography>

                {/* ),
              )} */}
              </Box>
            </Box>
            <Box sx={{ borderBottom: '1px solid #DEE2E6', padding: '1px' }}></Box>
          </Box>
          <Box
            sx={{
              gridArea: 'matchStatusAndTime',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              gap: '0.4px',
            }}
          >
            <Typography
              sx={{
                fontWeight: 500,
                ontSize: '0.8125rem',
                lineHeight: '1.25rem',
                color: EVENT_STATUS_COLORS[status as keyof {}],
              }}
              variant="caption"
              component={'span'}
            >
              {statusText || ''}
            </Typography>

            {endDate && (
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: '0.8125rem',
                  lineHeight: '1.25rem',
                }}
              >
                END {`${format(new Date(endDate), 'hh:mm a')} PST`}
              </Typography>
            )}
          </Box>
        </Paper>
      </Box>
    );
  },
);

MatchCardMASL.displayName = 'MatchCardMASL';

export default MatchCardMASL;
