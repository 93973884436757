import React from 'react';
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import v from 'voca';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { zonedTimeToUtc } from 'date-fns-tz';

import { IEvent, ITour } from '@/types/newTypes/event';
import { editCellTextFieldStyle, headToHeadTableStyles } from '@/utils/TableMisc';
import { EVENT_STATUS_OPTIONS } from '@/helpers/events';

import SPORT_TYPES from '@/constants/sportTypes';

import { displayInPT } from '@/helpers/timeConverters';

import useUpdateEvent from '@/hooks/events/useUpdateEvent';
import useAddEvents from '@/hooks/events/useAddEvents';

import { invalidateCachedEvent, invalidateCachedSports } from '@/helpers/cachedQueries';

import { UPDATE_EVENT_PAYLOAD } from '@/hooks/events/useUpdateEvent';

import keys from '@/constants/queryKeys';
import { SPORTS_EVENT_SEEDS_WITH_WEIGHT, DEFAULT_TIMEZONE } from '@/constants/misc';
import useFetchTours from '@/hooks/events/useFetchTours';

const InfoIcon = styled(InfoOutlinedIcon)({
  color: '#0D6EFD',
  // marginRight: '5px',
  position: 'absolute',
});

const BootstrapDialog = styled(Dialog)(() => ({
  '& .MuiDialogTitle-root': {
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    position: 'relative',
  },
  '& .MuiDialogContent-root': {
    padding: '1.5rem 1.5rem',
  },
  '& .MuiDialogActions-root': {
    padding: '1rem 1.5rem 1.5rem 3rem',
  },
}));

const FormLabel = styled(Typography)({
  fontWeight: 400,
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
});

type EventDetailsModalProps = {
  open: boolean;
  handleClose: () => void;
  title?: string;
  event: Partial<IEvent>;
  sportType: string;
  isEditEvent?: boolean;
  refreshEvents?: VoidFunction;
};

type EventLocationGroupOptionProp = { id?: string; label: string; inputValue?: string };

const filter = createFilterOptions<EventLocationGroupOptionProp>();

export const DATE_TYPE = {
  START_DATE: 'startDate',
  END_DATE: 'endDate',
};

const EventDetailsModal = (props: EventDetailsModalProps) => {
  const {
    open,
    handleClose,
    title = 'THIS WILL UPDATE THE EVENT DETAILS THROUGHOUT THE SYSTEM. ',
    event,
    sportType,
    isEditEvent = true,
    refreshEvents,
  } = props;

  const { mutateAsync: addEvents } = useAddEvents();
  const { mutateAsync: updateEvent } = useUpdateEvent();
  const { eventId = '' } = useParams();
  const queryClient = useQueryClient();

  const { data: toursData, isLoading: toursLoading } = useFetchTours(sportType, 'Event');

  const tours: ITour[] | [] = React.useMemo(() => toursData || [], [toursData]);

  const eventLocationGroups: { id: string; eventLocationGroup: string }[] | undefined =
    queryClient.getQueryData([keys.events.fetchEventLocationGroup, sportType, 'Event']);

  const EVENT_LOCATION_GROUPS: EventLocationGroupOptionProp[] = React.useMemo(() => {
    return (
      (eventLocationGroups &&
        eventLocationGroups.map((eLocGrp) => ({
          id: eLocGrp?.id,
          label: eLocGrp?.eventLocationGroup,
        }))) ||
      []
    );
  }, [eventLocationGroups]);

  const currentDate = new Date();
  const initialValues = {
    name: event?.name || '',
    tour: sportType === SPORT_TYPES.SLS ? event?.league?.id || '' : event?.tour?.id || '',
    gender: sportType === SPORT_TYPES.SLS ? event?.league?.gender || '' : event?.tour?.gender || '',
    year: event?.year || '',
    eventStatus: event?.eventStatus,
    eventLocation: event?.eventLocation || '',
    eventLocationGroup: {
      label: event?.eventLocationGroup || '',
      inputValue: '',
    },
    // using PT here because the date returned by the server is always in UTC
    // and the user wants to see it in PT
    startDate: event?.startDate ? displayInPT(event.startDate, undefined, true) : undefined,
    endDate: event?.endDate ? displayInPT(event.endDate, undefined, true) : null,
    eventNumber: event?.eventNumber,
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: Yup.object().shape({
      startDate: Yup.string().required(),
      eventLocation: Yup.string().required(),
      year: Yup.number().required(),
      tour: Yup.mixed().required(),
    }),
    onSubmit: () => {
      const requestPayload: UPDATE_EVENT_PAYLOAD = formatPayload();
      if (isEditEvent) {
        updateEvent(
          { sport: sportType, eventId, requestPayload: requestPayload },
          {
            onSettled: (_, err) => {
              if (!err) {
                invalidateCachedSports(queryClient);
                invalidateCachedEvent(queryClient, sportType, eventId, 'Event');
              }
              handleClose();
            },
          },
        );
      }
      if (!isEditEvent) {
        addEvents(
          { sport: sportType, requestPayload: { items: [requestPayload] } },
          {
            onSettled: (_, err) => {
              if (!err) {
                invalidateCachedSports(queryClient);
                if (refreshEvents) {
                  refreshEvents();
                }
              }
              handleClose();
            },
          },
        );
      }
    },
  });

  const {
    values,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
    errors,
    touched,
    setFieldTouched,
  } = formik;

  const timeHandler = (newValue: unknown, dateType: string) => {
    setFieldValue(dateType, newValue || null);
  };

  const { TOUR_OPTIONS, GENDER_OPTIONS, TOURS_WITH_YEARS } = React.useMemo(() => {
    const TOUR_OPTIONS = tours?.map((tour) => ({
      label: tour.name,
      value: tour.id,
      gender: tour.gender,
    }));

    const GENDER_OPTIONS: { [key: string]: string } = {};
    tours?.map((tour) => {
      GENDER_OPTIONS[tour.id] = tour.gender;
      return tour;
    });

    const TOURS_WITH_YEARS: { [key: string]: number[] } = {};
    tours?.map((tour) => {
      const years = tour.years;
      TOURS_WITH_YEARS[tour.id] = years.map(({ year }) => year).sort((a, b) => a - b);
      return tour;
    });
    return { TOUR_OPTIONS, GENDER_OPTIONS, TOURS_WITH_YEARS };
  }, [tours]);

  const { YEAR_OPTIONS, YEAR_IDS } = React.useMemo(() => {
    const currentSelectedTour = tours?.find((tour) => tour.id === values.tour);
    const years = currentSelectedTour?.years || [];

    const YEAR_IDS: { [key: number]: string } = {};
    const YEAR_OPTIONS = years
      .sort((a, b) => a.year - b.year)
      .map(({ year, id }) => {
        YEAR_IDS[year] = id;
        return { label: year, value: year };
      });
    // setFieldValue('year', YEAR_OPTIONS.length > 0 && YEAR_OPTIONS[YEAR_OPTIONS.length - 1].value);
    return { YEAR_OPTIONS, YEAR_IDS };
  }, [values.tour]);

  const formatPayload = () => {
    const payload: UPDATE_EVENT_PAYLOAD = {
      ...(!isEditEvent
        ? {
            gender: values.gender,
            tourName: TOUR_OPTIONS?.find((tr) => tr.value === values?.tour)?.label || '',
            year: values?.year,
          }
        : {}),
      isSimulationEnabled: event?.isSimulationEnabled || isEditEvent ? false : true,
      name: values.name,
      // converting it to UTC because when the date picker returns the date, it returns it in the local usertimezone
      // but in this case, we expect the user to enter the date in the PST time zone so we tell date fns that the date
      // received is in PST and then convert it to UTC so when the date is received back from the server, we get UTC and reverse it
      startDate: zonedTimeToUtc(values.startDate as string, DEFAULT_TIMEZONE),
      endDate: values.endDate ? zonedTimeToUtc(values.endDate as string, DEFAULT_TIMEZONE) : null,
      eventLocation: values.eventLocation,
      eventLocationGroup:
        (values?.eventLocationGroup && values?.eventLocationGroup?.inputValue
          ? values?.eventLocationGroup?.inputValue
          : values?.eventLocationGroup?.label) || '',
      eventStatus: values.eventStatus,
      ...(sportType === SPORT_TYPES.SLS
        ? {
            leagueYearId: YEAR_IDS[values.year as keyof {}],
          }
        : {
            tourYearId: YEAR_IDS[values.year as keyof {}],
            eventNumber: values?.eventNumber && +values?.eventNumber,
          }),
      ...(SPORTS_EVENT_SEEDS_WITH_WEIGHT.includes(sportType) && { weights: event?.weights || [] }),
    };
    return payload;
  };

  return (
    <BootstrapDialog
      open={open}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') {
          handleClose();
        }
      }}
      fullWidth
      maxWidth="xs"
    >
      <form>
        <DialogTitle>
          <InfoIcon />
          <Typography
            sx={{
              fontWeight: 500,
              paddingLeft: '2rem',
              fontSize: '0.875rem',
              lineHeight: '1.5rem',
            }}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container sx={{ gap: '0.6rem' }}>
            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Name</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>{`${sportType === SPORT_TYPES.SLS ? 'League' : 'Tour'}`}</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <FormControl error={errors?.tour && touched?.tour ? true : false} fullWidth>
                  <Select
                    name="tour"
                    value={values.tour}
                    onChange={(event: SelectChangeEvent<typeof values.tour>) => {
                      const {
                        target: { value },
                      } = event;
                      setFieldValue('tour', value);
                      setFieldValue('gender', GENDER_OPTIONS[value as keyof {}]);
                      const tourYears = TOURS_WITH_YEARS[value as keyof {}];
                      tourYears.length > 0 &&
                        setFieldValue('year', tourYears[tourYears.length - 1]);
                    }}
                    sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                    fullWidth
                    error={errors?.tour && touched?.tour ? true : false}
                  >
                    {React.Children.toArray(
                      TOUR_OPTIONS?.map((option) => (
                        <MenuItem value={option.value}>{option.label}</MenuItem>
                      )),
                    )}
                  </Select>
                  {errors?.tour && touched?.tour && (
                    <FormHelperText>Tour/ League is required</FormHelperText>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Gender</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  name="gender"
                  value={v.titleCase(values.gender)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  disabled
                  sx={{ ...editCellTextFieldStyle }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Season Year</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <Select
                  name="year"
                  value={values.year}
                  onChange={(event: SelectChangeEvent<typeof values.year>) => {
                    const {
                      target: { value },
                    } = event;
                    setFieldValue('year', value);
                  }}
                  sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  fullWidth
                >
                  {React.Children.toArray(
                    YEAR_OPTIONS.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    )),
                  )}
                </Select>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Start Date</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <MobileDateTimePicker
                  format="MM/dd/yyyy HH:mm:ss"
                  value={values.startDate || currentDate}
                  onChange={(newValue: unknown) => {
                    setFieldTouched('startDate', true);
                    timeHandler(newValue, DATE_TYPE.START_DATE);
                  }}
                  sx={{ ...editCellTextFieldStyle }}
                  slotProps={{
                    textField: {
                      name: 'startDate',
                      helperText:
                        errors?.startDate && touched?.startDate ? 'Start Date is required' : '',
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>End Date</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <MobileDateTimePicker
                  format="MM/dd/yyyy HH:mm:ss"
                  value={values.endDate || currentDate}
                  onChange={(newValue: unknown) => {
                    setFieldTouched('endDate', true);
                    timeHandler(newValue, DATE_TYPE.END_DATE);
                  }}
                  sx={{ ...editCellTextFieldStyle }}
                  slotProps={{
                    textField: {
                      name: 'endDate',
                      helperText: errors?.endDate && touched?.endDate ? 'End Date is required' : '',
                      fullWidth: true,
                    },
                  }}
                />
              </Grid>
            </Grid>

            {![SPORT_TYPES.SLS, SPORT_TYPES.BYB].includes(sportType) && (
              <Grid container item xs={12}>
                <Grid container alignItems={'center'} item xs={3}>
                  <FormLabel>Stop Number</FormLabel>
                </Grid>
                <Grid item xs={9}>
                  <TextField
                    name="eventNumber"
                    value={values.eventNumber}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ ...editCellTextFieldStyle }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Location</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  name="eventLocation"
                  value={values.eventLocation}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ ...editCellTextFieldStyle }}
                  error={errors?.eventLocation && touched?.eventLocation ? true : false}
                  helperText={
                    errors?.eventLocation && touched?.eventLocation ? 'Location is required' : ''
                  }
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Location Group</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <Autocomplete
                  value={values.eventLocationGroup}
                  onChange={(_, newValue) => {
                    if (typeof newValue === 'string') {
                      setFieldValue('eventLocationGroup', {
                        label: newValue,
                      });
                    } else if (newValue && newValue?.inputValue) {
                      // Create a new value from the user input
                      setFieldValue('eventLocationGroup', {
                        label: newValue?.inputValue || '',
                        inputValue: newValue?.inputValue || '',
                      });
                    } else {
                      setFieldValue('eventLocationGroup', newValue);
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    const { inputValue = '' } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.label);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                        inputValue,
                        label: `Add "${inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  selectOnFocus
                  clearOnBlur
                  options={EVENT_LOCATION_GROUPS}
                  freeSolo
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: '0.442rem 0.5625rem',
                    },
                    '& .MuiInputBase-input': {
                      height: '0.41rem !important',
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="eventLocationGroup"
                      sx={{ ...editCellTextFieldStyle }}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid container alignItems={'center'} item xs={3}>
                <FormLabel>Status</FormLabel>
              </Grid>
              <Grid item xs={9}>
                <Select
                  name="eventStatus"
                  value={values.eventStatus}
                  onChange={(event: SelectChangeEvent<typeof values.eventStatus>) => {
                    const {
                      target: { value },
                    } = event;
                    setFieldValue('eventStatus', value);
                  }}
                  sx={{ height: 38, ...headToHeadTableStyles.athleteNameStyle }}
                  MenuProps={{ PaperProps: { sx: { maxHeight: 150 } } }}
                  fullWidth
                >
                  {React.Children.toArray(
                    EVENT_STATUS_OPTIONS.map((option) => (
                      <MenuItem value={option.value}>{option.label}</MenuItem>
                    )),
                  )}
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid container justifyContent={'flex-end'} alignItems="center" columnSpacing={{ xs: 1 }}>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleClose();
                }}
                fullWidth
                disableElevation
              >
                Cancel
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              sx={{ maxWidth: 'unset !important', flexBasis: 'fit-content !important' }}
            >
              <LoadingButton
                variant="contained"
                loading={toursLoading}
                onClick={() => handleSubmit()}
                fullWidth
                disableElevation
              >
                Save
              </LoadingButton>
            </Grid>
          </Grid>
        </DialogActions>
      </form>
    </BootstrapDialog>
  );
};

export default EventDetailsModal;
