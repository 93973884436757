import * as React from 'react';
import v from 'voca';

import { Box } from '@mui/material';

import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

import { marketOddsPayload } from '../marketHelper';

import { MASLMarketTypesValue } from '@/constants/masl';

import { MarketTypeToggleLabel, MarketToggleIcon } from '../components';
import { MASLGameContext } from '@/contexts/maslGame.context';
import { BTN_BG_COLOR, HEADER_BACKGROUND_COLOR, TEXT_COLOR } from '../constants';

const MarketToggleOption = ({
  value,
  oddId,
  toggleIsMarketActive,
  disabledMarkets,
}: {
  value: any;
  oddId: number;
  toggleIsMarketActive: Function;
  disabledMarkets: { [key: string]: boolean };
}) => {
  const { isDisabledMatch } = React.useContext(MASLGameContext);
  const isMarketActive = marketOddsPayload({ odds: [value] })?.every((odd) => odd?.isMarketActive);
  const isMarketDisabled = disabledMarkets[value.marketType] || false;
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1,
        borderRadius: '0.25rem',
        backgroundColor: isMarketActive
          ? HEADER_BACKGROUND_COLOR.ACTIVE
          : HEADER_BACKGROUND_COLOR.SUSPENDED,
      }}
    >
      <MarketTypeToggleLabel
        component="span"
        sx={{
          ...(!isMarketActive && {
            fontStyle: 'italic',
            color: TEXT_COLOR.GRAY,
          }),
        }}
      >
        {v.titleCase(MASLMarketTypesValue[value.marketType as keyof {}])}
      </MarketTypeToggleLabel>
      <Box>
        <MarketToggleIcon
          sx={{
            backgroundColor: isMarketActive ? BTN_BG_COLOR.ACTIVE : TEXT_COLOR.WHITE,
            '&:hover': {
              backgroundColor: isMarketActive ? BTN_BG_COLOR.ACTIVE : TEXT_COLOR.WHITE,
            },
            '&:disabled': {
              backgroundColor: isMarketActive ? BTN_BG_COLOR.ACTIVE : TEXT_COLOR.WHITE,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            toggleIsMarketActive({ value, oddId, isMarketActive: true });
          }}
          disabled={isDisabledMatch || isMarketDisabled}
          id={`activate-${v.kebabCase(MASLMarketTypesValue[value.marketType as keyof {}])}-btn`}
        >
          <CheckIcon
            sx={{
              fontSize: '1.2rem',
              color: isMarketDisabled
                ? TEXT_COLOR.GRAY
                : isMarketActive
                ? TEXT_COLOR.WHITE
                : TEXT_COLOR.GRAY,
              '&:hover': {
                color: isMarketDisabled
                  ? TEXT_COLOR.GRAY
                  : isMarketActive
                  ? TEXT_COLOR.WHITE
                  : TEXT_COLOR.GRAY,
              },
              '&:disabled': {
                color: isMarketDisabled
                  ? TEXT_COLOR.GRAY
                  : isMarketActive
                  ? TEXT_COLOR.WHITE
                  : TEXT_COLOR.GRAY,
              },
            }}
          />
        </MarketToggleIcon>
        <MarketToggleIcon
          sx={{
            backgroundColor: !isMarketActive ? BTN_BG_COLOR.SUSPENDED : TEXT_COLOR.WHITE,
            '&:hover': {
              backgroundColor: !isMarketActive ? BTN_BG_COLOR.SUSPENDED : TEXT_COLOR.WHITE,
            },
            '&:disabled': {
              backgroundColor: !isMarketActive ? BTN_BG_COLOR.SUSPENDED : TEXT_COLOR.WHITE,
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            toggleIsMarketActive({ value, oddId, isMarketActive: false });
          }}
          disabled={isDisabledMatch || isMarketDisabled}
          id={`suspend-${v.kebabCase(MASLMarketTypesValue[value.marketType as keyof {}])}-btn`}
        >
          <CloseIcon
            sx={{
              fontSize: '1.2rem',
              color: isMarketDisabled
                ? TEXT_COLOR.GRAY
                : !isMarketActive
                ? TEXT_COLOR.WHITE
                : TEXT_COLOR.GRAY,
              '&:hover': {
                color: isMarketDisabled
                  ? TEXT_COLOR.GRAY
                  : !isMarketActive
                  ? TEXT_COLOR.WHITE
                  : TEXT_COLOR.GRAY,
              },
              '&:disabled': {
                color: isMarketDisabled
                  ? TEXT_COLOR.GRAY
                  : !isMarketActive
                  ? TEXT_COLOR.WHITE
                  : TEXT_COLOR.GRAY,
              },
            }}
          />
        </MarketToggleIcon>
      </Box>
    </Box>
  );
};

export default MarketToggleOption;
