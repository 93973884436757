const SPORT_TYPES = {
  SLS: 'sls',
  WSL: 'wsl',
  NRX: 'nrx',
  SPR: 'spr',
  MASL: 'masl',
  FDRIFT: 'fdrift',
  MOTOCRS: 'motocrs',
  F1: 'f1',
  MOTOGP: 'motogp',
  MXGP: 'mxgp',
  JAIALAI: 'jaialai',
  NHRA: 'nhra',
  MOTOAMERICA: 'motoamerica',
  BYB: 'byb',
  BKFC: 'bkfc',
  USAC: 'usac',
  POWERSLAP: 'powerslap',
  SPRMTCRS: 'sprmtcrs',
  HLRS: 'hlrs',
};

export const sportOptions = [
  { label: 'World Surf League', value: SPORT_TYPES.WSL },
  { label: 'Street League Skateboarding', value: SPORT_TYPES.SLS },
  { label: 'Nitrocross', value: SPORT_TYPES.NRX },
  { label: 'AMA Supercross', value: SPORT_TYPES.SPR },
  { label: 'Major Arena Soccer League', value: SPORT_TYPES.MASL },
  { label: 'Formula Drift', value: SPORT_TYPES.FDRIFT },
  { label: 'Motocross', value: SPORT_TYPES.MOTOCRS },
  // { label: 'Formula 1', value: SPORT_TYPES.F1 },
  // { label: 'MotoGP', value: SPORT_TYPES.MOTOGP },
  // { label: 'Motocross World Championship', value: SPORT_TYPES.MXGP },
  { label: 'World Jai Alai', value: SPORT_TYPES.JAIALAI },
  { label: 'NHRA - Mission Foods Drag Racing Series', value: SPORT_TYPES.NHRA },
  { label: 'MotoAmerica', value: SPORT_TYPES.MOTOAMERICA },
  { label: 'BYB Extreme Fighting', value: SPORT_TYPES.BYB },
  { label: 'Bare Knuckle Fighting Championships', value: SPORT_TYPES.BKFC },
  { label: 'United States Auto Club Racing', value: SPORT_TYPES.USAC },
  { label: 'Power Slap', value: SPORT_TYPES.POWERSLAP },
  { label: 'Supermotocross', value: SPORT_TYPES.SPRMTCRS },
  { label: 'High Limit Race', value: SPORT_TYPES.HLRS },
];

export const SPORT_NAMES = {
  [SPORT_TYPES.WSL]: 'World Surf League',
  [SPORT_TYPES.SLS]: 'Street League Skateboarding',
  [SPORT_TYPES.NRX]: 'Nitrocross',
  [SPORT_TYPES.SPR]: 'AMA Supercross',
  [SPORT_TYPES.MASL]: 'Major Arena Soccer League',
  [SPORT_TYPES.FDRIFT]: 'Formula Drift',
  [SPORT_TYPES.MOTOCRS]: 'Motocross',
  [SPORT_TYPES.F1]: 'Formula 1',
  [SPORT_TYPES.MOTOGP]: 'MotoGP',
  [SPORT_TYPES.MXGP]: 'Motocross World Championship',
  [SPORT_TYPES.JAIALAI]: 'World Jai Alai',
  [SPORT_TYPES.NHRA]: 'NHRA - Mission Foods Drag Racing Series',
  [SPORT_TYPES.MOTOAMERICA]: 'MotoAmerica',
  [SPORT_TYPES.BYB]: 'BYB Extreme Fighting',
  [SPORT_TYPES.BKFC]: 'Bare Knuckle Fighting Championships',
  [SPORT_TYPES.USAC]: 'United States Auto Club Racing',
  [SPORT_TYPES.POWERSLAP]: 'Power Slap',
  [SPORT_TYPES.SPRMTCRS]: 'Supermotocross',
  [SPORT_TYPES.HLRS]: 'High Limit Race',
};

export const HIDDEN_SPORTS = [
  SPORT_TYPES.F1,
  SPORT_TYPES.MOTOGP,
  SPORT_TYPES.MXGP,
  SPORT_TYPES.SPR,
];

export default SPORT_TYPES;
