import { useEffect, useMemo, useState } from 'react';
import MaterialTable from '@material-table/core';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { parseISO } from 'date-fns';
import v from 'voca';

import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import RefreshIcon from '@mui/icons-material/Refresh';
import UploadFileIcon from '@mui/icons-material/UploadFile';

import AddIcon from '@mui/icons-material/Add';

import calendarImg from '@/images/sports/Calendar.png';

import Filters from './Filters';
// import { EventListingSkeleton } from '@/components/PageSkeletons';
import Head from '@/components/Head';
// import EventDetailsModal from '@/components/EventDetailsModal';

import useFetchEvents from '@/hooks/events/useFetchEvents';
import useFetchTours from '@/hooks/events/useFetchTours';
import useFetchEventLocationGroup from '@/hooks/events/useFetchEventLocationGroup';

import {
  processEventStatusBadges,
  sortEventsByStatusAndStartDate,
  sortEventsByStartDate,
} from '@/helpers/events';

import { theme } from '@/constants/theme';
import keys from '@/constants/queryKeys';
import routes from '@/constants/routes';
import { tableRowsWithGapsTheme } from '@/constants/theme';
import { eventStatus, EventStatusNames, EventStatusValues } from '@/constants/events';
import { SPORT_LOGOS } from '@/constants/misc';

import { displayInPT as format } from '@/helpers/timeConverters';

import { IEvent } from '@/types/newTypes/event';
import SPORT_TYPES from '@/constants/sportTypes';
import useFetchProfile from '@/hooks/user/useFetchProfile';

const ListingWrapper = styled(Grid)(
  ({ theme }) => {
    return {
      MuiTypography: {
        root: {
          color: theme.palette.info.main,
          display: 'inline-block',
          fontWeight: '500',
          fontSize: '2rem',
        },
      },
      MuiChip: {
        root: {
          backgroundColor: theme.palette.info.light,
        },
      },
      '& .sport-logo': {
        width: '3.125rem',
        imageRendering: '-webkit-optimize-contrast',
        verticalAlign: 'middle',
      },
    };
  },
  { defaultTheme: tableRowsWithGapsTheme },
);

type filterOption = {
  label: string;
  value: string | number;
};

type filterValues = {
  sport: filterOption | null;
  tour: filterOption | null;
  year: filterOption | null;
  status: any;
};

export const initialFilterValues = {
  sport: null,
  tour: null,
  year: null,
  status: eventStatus.filter(
    (evt) => evt.value === EventStatusValues.LIVE || evt.value === EventStatusValues.UPCOMING,
  ),
};

export default function EventListing() {
  const isSmallAndDown = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumAndDown = useMediaQuery(theme.breakpoints.down('md'));
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [filteredEvents, setFilteredEvents] = useState<any[]>([]);
  const [eventStatus, setEventStatus] = useState<number[]>([
    EventStatusValues.LIVE,
    EventStatusValues.UPCOMING,
  ]);
  const [queryParam, setQueryParam] = useState<string>('');
  const [filterLocal, setFilterLocal] = useState<filterValues>(initialFilterValues);

  const handleFilterLocal = ({ key, value }: { key: string; value: any }) => {
    setFilterLocal({ ...filterLocal, [key]: value });
  };

  // const [showEditEventDetailsModal, setShowEditEventDetailsModal] = React.useState(false);

  // const handleToggleShowEditEventDetailsModal = () =>
  //   setShowEditEventDetailsModal((prevVal) => !prevVal);

  const { data: currentUser } = useFetchProfile();

  useFetchTours(SPORT_TYPES.BYB, 'Event');
  useFetchEventLocationGroup(SPORT_TYPES.BYB, 'Event');

  const getSportType = (sType: string) => {
    return currentUser?.restrictedSportKeys.length > 0
      ? currentUser?.restrictedSportKeys.includes(sType) && sType
      : sType;
  };

  const { data: wslEvents, isFetching: wslIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.WSL),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: slsEvents, isFetching: slsIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.SLS),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: nrxEvents, isFetching: nrxIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.NRX),
    queryParam,
    eventStatus,
    currentUser,
  });
  const { data: maslGames, isFetching: maslIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.MASL),
    queryParam,
    eventStatus,
    currentUser,
  });
  const { data: fdriftEvents, isFetching: fdriftIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.FDRIFT),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: motocrsEvents, isFetching: motocrsIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.MOTOCRS),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: jaialaiEvents, isFetching: jaialaiIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.JAIALAI),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: nhraEvents, isFetching: nhraIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.NHRA),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: motoAmericaEvents, isFetching: motoAmericaIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.MOTOAMERICA),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: bybEvents, isFetching: bybIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.BYB),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: bkfcEvents, isFetching: bkfcIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.BKFC),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: usacEvents, isFetching: usacIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.USAC),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: powerslapEvents, isFetching: powerslapIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.POWERSLAP),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: sprmtcrsEvents, isFetching: sprmtcrsIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.SPRMTCRS),
    queryParam,
    eventStatus,
    currentUser,
  });

  const { data: hlrsEvents, isFetching: hlrsIsFetching } = useFetchEvents({
    sportType: getSportType(SPORT_TYPES.HLRS),
    queryParam,
    eventStatus,
    currentUser,
  });

  // isLoading,
  const refreshList = () => {
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.WSL, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.SLS, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.NRX, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.MASL, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.FDRIFT, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.MOTOCRS, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.JAIALAI, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.NHRA, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.MOTOAMERICA, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.BYB, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.BKFC, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.USAC, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.POWERSLAP, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.SPRMTCRS, eventStatus]);
    queryClient.invalidateQueries([keys.events.fetchEvents, SPORT_TYPES.HLRS, eventStatus]);
  };

  /* Refetch events when page revisted */
  useEffect(() => {
    return () => refreshList();
  }, []);

  const mergedEvents = useMemo(() => {
    return [
      ...(wslEvents || []),
      ...(slsEvents || []),
      ...(nrxEvents || []),
      ...(maslGames || []),
      ...(fdriftEvents || []),
      ...(motocrsEvents || []),
      ...(jaialaiEvents || []),
      ...(nhraEvents || []),
      ...(motoAmericaEvents || []),
      ...(bybEvents || []),
      ...(bkfcEvents || []),
      ...(usacEvents || []),
      ...(powerslapEvents || []),
      ...(sprmtcrsEvents || []),
      ...(hlrsEvents || []),
    ];
  }, [
    wslEvents,
    slsEvents,
    nrxEvents,
    maslGames,
    fdriftEvents,
    motocrsEvents,
    jaialaiEvents,
    nhraEvents,
    motoAmericaEvents,
    bybEvents,
    bkfcEvents,
    usacEvents,
    powerslapEvents,
    sprmtcrsEvents,
    hlrsEvents,
  ]);

  const fEvents = useMemo(() => {
    const { sport, tour, year } = filterLocal;
    let filteredArr: IEvent[];
    filteredArr = JSON.parse(JSON.stringify(mergedEvents));
    if (sport) {
      filteredArr = filteredArr.filter((event: IEvent) => event?.sportType === sport?.value);
    }
    if (tour) {
      filteredArr = filteredArr.filter(
        (event: IEvent) => event?.tour?.name === tour?.label || event?.league?.name === tour?.label,
      );
    }
    if (year) {
      filteredArr = filteredArr.filter((event: IEvent) => event?.year?.toString() === year?.label);
    }
    return filteredArr;
  }, [mergedEvents, filterLocal]);

  useEffect(() => {
    const sortedEvents =
      eventStatus.length > 0
        ? sortEventsByStartDate(fEvents)
        : sortEventsByStatusAndStartDate(fEvents);
    setFilteredEvents(sortedEvents);
  }, [fEvents, eventStatus]);

  return (
    <Box>
      <Head title={'Altsportsdata - Home'} canonicalUrl={routes.events.home} />
      {/* {isLoading ? (
        <EventListingSkeleton />
      ) : ( */}
      <ListingWrapper>
        {/* <EventDetailsModal
          open={showEditEventDetailsModal}
          handleClose={handleToggleShowEditEventDetailsModal}
          event={{
            name: '',
            startDate: '',
            endDate: '',
            eventLocation: '',
            eventLocationGroup: '',
            eventStatus: 2,
            isSimulationEnabled: false,
          }}
          sportType={SPORT_TYPES.BYB}
          title="Add information about BYB event."
          refreshEvents={refreshList}
          isEditEvent={false}
        /> */}
        <Filters
          allEvents={mergedEvents}
          setFilteredEvents={setFilteredEvents}
          setEventStatus={setEventStatus}
          setQueryParam={setQueryParam}
          handleFilterLocal={handleFilterLocal}
        />
        <Grid
          container
          justifyContent="space-between"
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <Grid item sx={{ display: 'flex', alignItems: 'center' }} className={'listing-head'}>
            {eventStatus?.includes(EventStatusValues[EventStatusNames.COMPLETED as keyof {}]) ? (
              <>
                <Typography variant="h5">{`${format(new Date(), 'MMMM')}`}&nbsp;</Typography>
                <Typography variant="h5">{` ${format(new Date(), ' yyyy')}`}&nbsp;</Typography>
              </>
            ) : (
              <Typography variant="h5">{v.titleCase(EventStatusNames.UPCOMING)}&nbsp;</Typography>
            )}

            <Chip label={`${filteredEvents?.length} events`} size="small" />
          </Grid>
          <Grid item>
            {/* <Tooltip title="Add Event"> */}
            <Button
              variant="contained"
              disableElevation
              startIcon={<AddIcon />}
              onClick={() => navigate(routes.events.add)}
            >
              Add Event
            </Button>
            {/* </Tooltip> */}
            <Tooltip title="Upload Events">
              <IconButton aria-label="upload" onClick={() => navigate(routes.events.upload)}>
                <UploadFileIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Refresh Events">
              <IconButton
                disabled={
                  wslIsFetching ||
                  slsIsFetching ||
                  nrxIsFetching ||
                  maslIsFetching ||
                  fdriftIsFetching ||
                  motocrsIsFetching ||
                  jaialaiIsFetching ||
                  nhraIsFetching ||
                  motoAmericaIsFetching ||
                  bybIsFetching ||
                  bkfcIsFetching ||
                  usacIsFetching ||
                  powerslapIsFetching ||
                  sprmtcrsIsFetching ||
                  hlrsIsFetching
                }
                aria-label="refresh"
                onClick={() => refreshList()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <ThemeProvider theme={tableRowsWithGapsTheme}>
          <MaterialTable
            components={{
              Container: (props) => (
                <div style={{ background: 'transparent' }}>{props.children}</div>
              ),
            }}
            columns={[
              { title: 'Id', field: 'id', hidden: true, width: '5%' },
              {
                title: 'Event Type',
                field: 'name',
                cellStyle: {
                  // Custom column size
                  width: 85,
                  textAlign: 'center',
                  paddingRight: 0,
                },
                headerStyle: {
                  // Custom column size
                  width: 85,
                  textAlign: 'center',
                  paddingRight: 0,
                },
                render: (rowData: IEvent) => (
                  <Box
                    component="img"
                    className="sport-logo"
                    src={SPORT_LOGOS[rowData.sportType]}
                    alt="Sport logo"
                  />
                ),
              },
              {
                title: 'Event Name',
                field: 'name',
                cellStyle: {
                  // Custom column size
                  width: 650,
                },
                headerStyle: {
                  // Custom column size
                  width: 650,
                },
                render: (rowData: IEvent) => (
                  <Box>
                    <Typography color="primary" sx={{ fontWeight: '500' }}>
                      {rowData.name}
                    </Typography>
                    {!isSmallAndDown && (
                      <Typography
                        color="info.main"
                        sx={{ display: 'inline-block', fontSize: '0.87rem' }}
                      >
                        {[
                          rowData?.eventLocation,
                          rowData?.tour?.name,
                          rowData?.league?.name,
                          rowData?.eventNumber ? `Stop #${rowData?.eventNumber}` : '',
                        ]
                          .filter(Boolean)
                          .join(' • ')}
                      </Typography>
                    )}
                  </Box>
                ),
              },
              {
                title: 'Event Date',
                field: 'startDate',
                hidden: isMediumAndDown,
                cellStyle: {
                  // Custom column size
                  width: 250,
                },
                headerStyle: {
                  // Custom column size
                  width: 250,
                },
                render: (rowData: IEvent) => {
                  const { startDate, endDate } = rowData;
                  const _startDate = startDate && format(parseISO(startDate), 'MMM dd');
                  const _endDate = endDate && format(parseISO(endDate), 'MMM dd');
                  const dates =
                    rowData?.sportType === SPORT_TYPES.JAIALAI
                      ? [_startDate || 'TBD']
                      : [_startDate || 'TBD', _endDate || 'TBD'];
                  const dateArray = [...new Set(dates)].filter(Boolean).join(' - ');
                  return (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Box
                        component="img"
                        src={calendarImg}
                        alt="calendar"
                        sx={{
                          width: '1rem',
                          imageRendering: '-webkit-optimize-contrast',
                          marginRight: '0.62rem',
                        }}
                      />
                      <Typography
                        color="info.main"
                        sx={{
                          fontWeight: '500',
                          lineHeight: '1',
                          fontSize: '0.87rem',
                          padding: '0.12em 0',
                          height: ' 1.03em',
                        }}
                      >
                        {dateArray}
                      </Typography>
                    </Box>
                  );
                },
              },
              {
                title: 'Status',
                field: 'eventStatus',
                cellStyle: {
                  // Custom column size
                  width: 110,
                },
                headerStyle: {
                  // Custom column size
                  width: 110,
                },
                render: (rowData: IEvent) => processEventStatusBadges(rowData.eventStatus),
              },
            ]}
            data={filteredEvents}
            options={{
              pageSize: 15,
              pageSizeOptions: [15, 25, 50],
              toolbar: false,
              header: false,
              actionsColumnIndex: -1,
              rowStyle: {
                overflowWrap: 'break-word',
              },
            }}
            actions={
              [
                /* {
                  icon: 'more_horiz',
                  tooltip: `Last Synced ${events?.fetchedTime}`,
                  onClick: () => {
                    //
                  },
                }, */
              ]
            }
            onRowClick={(event, rowData) => {
              event?.stopPropagation();
              navigate(
                [SPORT_TYPES.JAIALAI, SPORT_TYPES.MASL].includes(rowData?.sportType || '')
                  ? `/${rowData?.sportType}/event-games/${rowData?.id}`
                  : `/${rowData?.sportType}/event/${rowData?.id}`,
                { replace: true },
              );
            }}
            title="Events"
          />
        </ThemeProvider>
      </ListingWrapper>
      {/* )} */}
    </Box>
  );
}
