import { IconButton } from '@mui/material';
import v from 'voca';
import * as React from 'react';

import { JASubMarketTypes, JASubMarketTypesValues } from '@/constants/jaialai';

import {
  // Styled Components
  GridCell,
  UnLockedIcon,
  LockedIcon,
  CalculatedDecimalField,
  // Normal Components
  CommonOddField,
} from '../components';
import { teamTypeToKey } from '../constants';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import DebugLabelDisplay from '../DebugLabelDisplay';

const SpreadColumn = ({
  bet,
  oddType,
  toggleSubMarketLock,
  updateSubMarketTeam,
  updateSubMarketTeamKey,
  commonBoxShadowStyles,
  id,
}: {
  bet: any;
  oddType: number;
  toggleSubMarketLock: Function;
  updateSubMarketTeam: Function;
  updateSubMarketTeamKey: Function;
  commonBoxShadowStyles: any;
  id: string;
}) => {
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);
  const subMarketTypeNameKebabCase = v.kebabCase(
    JASubMarketTypesValues[JASubMarketTypes.SPREAD as keyof {}] || '',
  );
  const isSubMarketLocked =
    bet[JASubMarketTypes.SPREAD]?.[teamTypeToKey.homeTeam]?.isSubMarketLocked &&
    bet[JASubMarketTypes.SPREAD]?.[teamTypeToKey.awayTeam]?.isSubMarketLocked;
  return (
    <GridCell
      sx={{
        gridArea: 'spread',
        justifyContent: 'start',
        display: 'grid',
        gridTemplateAreas: `"lock ." "homeTeamCalculatedValue homeTeamSpread" "awayTeamCalculatedValue awayTeamSpread"`,
        gridTemplateRows: 'repeat(3, minmax(0, 50px))',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: '2px',
        ...commonBoxShadowStyles,
      }}
    >
      <GridCell
        sx={{
          gridArea: 'lock',
          boxShadow: 'none',
          justifyContent: 'flex-start',
        }}
      >
        <IconButton
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            const subMarketType = JASubMarketTypes.SPREAD;
            toggleSubMarketLock(subMarketType);
          }}
          id={`toggle-${id}-${subMarketTypeNameKebabCase}-market-lock`}
          disabled={isDisabledMatch}
        >
          {isSubMarketLocked ? <LockedIcon /> : <UnLockedIcon />}
        </IconButton>
      </GridCell>

      <GridCell
        sx={{
          gridArea: 'homeTeamCalculatedValue',
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <DebugLabelDisplay row={bet?.[JASubMarketTypes.SPREAD]?.[teamTypeToKey.homeTeam]} />
        <CalculatedDecimalField
          id={`${id}-${subMarketTypeNameKebabCase}-homeTeamCalculatedValue`}
          allowNegative
          value={bet?.[JASubMarketTypes.SPREAD]?.[teamTypeToKey.homeTeam]?.calculatedValue}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            const val = +e.target.value || 0;
            // const valInv = (+e.target.value || 0) * -1;
            updateSubMarketTeamKey({
              subMarketType: JASubMarketTypes.SPREAD,
              teamType: teamTypeToKey.homeTeam,
              key: 'calculatedValue',
              val: val,
            });
            // updateSubMarketTeamKey({
            //   subMarketType: JASubMarketTypes.SPREAD,
            //   teamType: teamTypeToKey.awayTeam,
            //   key: 'calculatedValue',
            //   val: valInv,
            // });
            updateSubMarketTeamKey({
              subMarketType: JASubMarketTypes.SPREAD,
              teamType: teamTypeToKey.homeTeam,
              key: 'hasModifiedProbability',
              val: true,
            });
          }}
          InputProps={{
            readOnly: true,
          }}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
      </GridCell>

      <GridCell
        sx={{
          gridArea: 'awayTeamCalculatedValue',
          boxShadow: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
        }}
      >
        <DebugLabelDisplay row={bet?.[JASubMarketTypes.SPREAD]?.[teamTypeToKey.awayTeam]} />
        <CalculatedDecimalField
          id={`${id}-${subMarketTypeNameKebabCase}-awayTeamCalculatedValue`}
          allowNegative
          value={bet?.[JASubMarketTypes.SPREAD]?.[teamTypeToKey.awayTeam]?.calculatedValue}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
            const val = +e.target.value || 0;
            // const valInv = (+e.target.value || 0) * -1;
            updateSubMarketTeamKey({
              subMarketType: JASubMarketTypes.SPREAD,
              teamType: teamTypeToKey.awayTeam,
              key: 'calculatedValue',
              val: val,
            });
            // updateSubMarketTeamKey({
            //   subMarketType: JASubMarketTypes.SPREAD,
            //   teamType: teamTypeToKey.homeTeam,
            //   key: 'calculatedValue',
            //   val: valInv,
            // });
            updateSubMarketTeamKey({
              subMarketType: JASubMarketTypes.SPREAD,
              teamType: teamTypeToKey.awayTeam,
              key: 'hasModifiedProbability',
              val: true,
            });
          }}
          InputProps={{
            readOnly: true,
          }}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
      </GridCell>

      <GridCell
        sx={{
          gridArea: 'homeTeamSpread',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          boxShadow: 'none',
        }}
      >
        <DebugLabelDisplay row={bet?.[JASubMarketTypes.SPREAD]?.[teamTypeToKey.homeTeam]} />
        <CommonOddField
          id={`${id}-${subMarketTypeNameKebabCase}-homeTeam-odds`}
          oddType={oddType}
          bet={bet}
          subMarketType={JASubMarketTypes.SPREAD}
          teamType={teamTypeToKey.homeTeam}
          updateSubMarketTeam={updateSubMarketTeam}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
      </GridCell>

      <GridCell
        sx={{
          gridArea: 'awayTeamSpread',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          boxShadow: 'none',
        }}
      >
        <DebugLabelDisplay row={bet?.[JASubMarketTypes.SPREAD]?.[teamTypeToKey.awayTeam]} />
        <CommonOddField
          id={`${id}-${subMarketTypeNameKebabCase}-awayTeam-odds`}
          oddType={oddType}
          bet={bet}
          subMarketType={JASubMarketTypes.SPREAD}
          teamType={teamTypeToKey.awayTeam}
          updateSubMarketTeam={updateSubMarketTeam}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
      </GridCell>
    </GridCell>
  );
};

export default SpreadColumn;
