import v from 'voca';
import { IconButton } from '@mui/material';
import * as React from 'react';

import { JASubMarketTypes, JASubMarketTypesValues } from '@/constants/jaialai';

import {
  // Styled Components
  GridCell,
  UnLockedIcon,
  LockedIcon,
  // Normal Components
  CommonOddField,
} from '../components';
import { teamTypeToKey } from '../constants';
import { JAIALAIGameContext } from '@/contexts/jaialaiGame.context';
import DebugLabelDisplay from '../DebugLabelDisplay';

const MoneyLineColumn = ({
  bet,
  oddType,
  toggleSubMarketLock,
  updateSubMarketTeam,
  commonBoxShadowStyles,
  id,
}: {
  bet: any;
  oddType: number;
  toggleSubMarketLock: Function;
  updateSubMarketTeam: Function;
  commonBoxShadowStyles: any;
  id: string;
}) => {
  const { isDisabledMatch } = React.useContext(JAIALAIGameContext);
  const subMarketTypeNameKebabCase = v.kebabCase(
    JASubMarketTypesValues[JASubMarketTypes.MONEY_LINE as keyof {}] || '',
  );
  const isSubMarketLocked =
    bet[JASubMarketTypes.MONEY_LINE]?.[teamTypeToKey.homeTeam]?.isSubMarketLocked &&
    bet[JASubMarketTypes.MONEY_LINE]?.[teamTypeToKey.awayTeam]?.isSubMarketLocked;
  return (
    <GridCell
      sx={{
        gridArea: 'moneyLine',
        justifyContent: 'start',
        display: 'grid',
        gridTemplateAreas: `"lock ."  ". homeTeamMoneyLine" ". awayTeamMoneyLine"`,
        gridTemplateRows: 'repeat(3, minmax(0, 50px))',
        gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
        gap: '2px',
        ...commonBoxShadowStyles,
      }}
    >
      <GridCell
        sx={{
          gridArea: 'lock',
          boxShadow: 'none',
          justifyContent: 'flex-start',
        }}
      >
        <IconButton
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            const subMarketType = JASubMarketTypes.MONEY_LINE;
            toggleSubMarketLock(subMarketType);
          }}
          id={`toggle-${id}-${subMarketTypeNameKebabCase}-market-lock`}
          disabled={isDisabledMatch}
        >
          {isSubMarketLocked ? <LockedIcon /> : <UnLockedIcon />}
        </IconButton>
      </GridCell>
      <GridCell
        sx={{
          gridArea: 'homeTeamMoneyLine',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          boxShadow: 'none',
        }}
      >
        <DebugLabelDisplay row={bet[JASubMarketTypes.MONEY_LINE]?.[teamTypeToKey.homeTeam]} />
        <CommonOddField
          id={`${id}-${subMarketTypeNameKebabCase}-homeTeam-odds`}
          oddType={oddType}
          bet={bet}
          subMarketType={JASubMarketTypes.MONEY_LINE}
          teamType={teamTypeToKey.homeTeam}
          updateSubMarketTeam={updateSubMarketTeam}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
      </GridCell>

      <GridCell
        sx={{
          gridArea: 'awayTeamMoneyLine',
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          boxShadow: 'none',
        }}
      >
        <DebugLabelDisplay row={bet[JASubMarketTypes.MONEY_LINE]?.[teamTypeToKey.awayTeam]} />
        <CommonOddField
          id={`${id}-${subMarketTypeNameKebabCase}-awayTeam-odds`}
          oddType={oddType}
          bet={bet}
          subMarketType={JASubMarketTypes.MONEY_LINE}
          teamType={teamTypeToKey.awayTeam}
          updateSubMarketTeam={updateSubMarketTeam}
          disabled={isDisabledMatch || isSubMarketLocked}
        />
      </GridCell>
    </GridCell>
  );
};

export default MoneyLineColumn;
