import React from 'react';
import v from 'voca';

import { Box, styled, Typography } from '@mui/material';

import { CounterButton } from '../Markets/components';
import {
  externalMarketOddsPayload,
  computeScores,
  getFirstUnlockedOrLockedSubMkt,
  findMode,
} from '../Markets/marketHelper';
import { GameEvent } from '@/types/game';

import { allRoundsDefault, allRoundsOrder, ComputedMASLRounds } from '@/constants/masl';

import DebugLabelDisplay from '../Markets/DebugLabelDisplay';
import { MASLGameContext } from '@/contexts/maslGame.context';
import { BORDER_COLOR } from '../Markets/constants';

const ScoresLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: '0.8125rem',
  lineHeight: '1.25rem',
});

const CellText = styled(Typography)({
  fontSize: '0.8125rem',
  lineHeight: '1.25rem',
});

const GridCell = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0.8rem',
  boxShadow: `0 0 0 1px ${BORDER_COLOR.ACTIVE}`,
});

export default function GameScores({
  game,
  formik,
  externalUpdateOdds,
}: {
  game: GameEvent;
  formik: any;
  externalUpdateOdds: any;
}) {
  const { isDisabledMatch } = React.useContext(MASLGameContext);

  const { homeTeam, awayTeam, homeTeamAllRoundScores, awayTeamAllRoundScores } =
    React.useMemo(() => {
      const _homeTeamAllRoundScores: Record<string, null | number> = { ...allRoundsDefault };
      const _awayTeamAllRoundScores: Record<string, null | number> = { ...allRoundsDefault };
      if (game?.teams) {
        const { teams = [] } = game;

        const _homeTeam = teams.find((team) => team.isHomeTeam);
        const _awayTeam = teams.find((team) => !team.isHomeTeam);
        game?.rounds.forEach((evtRound) => {
          const scores = evtRound?.scores || [];
          scores.forEach((scr) => {
            if (scr?.teamId === _homeTeam?.id) {
              _homeTeamAllRoundScores[evtRound.name] = scr?.score || null;
            } else {
              _awayTeamAllRoundScores[evtRound.name] = scr?.score || null;
            }
          });
        });
        // Computed Scores
        _homeTeamAllRoundScores[ComputedMASLRounds.Half] = computeScores({
          scores: _homeTeamAllRoundScores,
          roundType: ComputedMASLRounds.Half,
        });
        _homeTeamAllRoundScores[ComputedMASLRounds.Reg] = computeScores({
          scores: _homeTeamAllRoundScores,
          roundType: ComputedMASLRounds.Reg,
        });
        _homeTeamAllRoundScores[ComputedMASLRounds.Full] = computeScores({
          scores: _homeTeamAllRoundScores,
          roundType: ComputedMASLRounds.Full,
        });

        _awayTeamAllRoundScores[ComputedMASLRounds.Half] = computeScores({
          scores: _awayTeamAllRoundScores,
          roundType: ComputedMASLRounds.Half,
        });
        _awayTeamAllRoundScores[ComputedMASLRounds.Reg] = computeScores({
          scores: _awayTeamAllRoundScores,
          roundType: ComputedMASLRounds.Reg,
        });
        _awayTeamAllRoundScores[ComputedMASLRounds.Full] = computeScores({
          scores: _awayTeamAllRoundScores,
          roundType: ComputedMASLRounds.Full,
        });

        return {
          homeTeam: _homeTeam,
          awayTeam: _awayTeam,
          homeTeamAllRoundScores: _homeTeamAllRoundScores,
          awayTeamAllRoundScores: _awayTeamAllRoundScores,
        };
      }
      return {
        homeTeam: undefined,
        awayTeam: undefined,
        homeTeamAllRoundScores: _homeTeamAllRoundScores,
        awayTeamAllRoundScores: _awayTeamAllRoundScores,
      };
    }, [game]);

  const { values } = formik;

  const { homeTeam: homeTeamDtls, awayTeam: awayTeamDtls } = React.useMemo(
    () => getFirstUnlockedOrLockedSubMkt(values?.odds),
    [values?.odds],
  );

  const handleExternalUpdateOdds = ({
    lean,
    isHomeTeam = true,
  }: {
    lean: number;
    isHomeTeam: boolean;
  }) => {
    const odds = values?.odds || [];
    const formattedData = externalMarketOddsPayload({ odds });

    // Filter the data into home and away teams
    const homeTeamData = formattedData.filter((data: any) => data.isHomeTeam);
    const awayTeamData = formattedData.filter((data: any) => !data.isHomeTeam);

    // Find the mode for lean in home and away teams
    const homeTeamLean = findMode(homeTeamData, 'lean');
    const awayTeamLean = findMode(awayTeamData, 'lean');

    const finalPayload = formattedData.map((data: any) => {
      return {
        ...data,
        lean:
          data.isHomeTeam === isHomeTeam ? lean : data.isHomeTeam ? +homeTeamLean : +awayTeamLean,
      };
    });

    externalUpdateOdds({ odds: finalPayload, originalOdds: odds });
  };

  const matchName = React.useMemo(() => v.kebabCase(game?.matchName || '') || '', [game]);

  return (
    <>
      <Box
        id={`${matchName}`}
        sx={{
          display: 'grid',
          gridTemplateAreas: `"indicator teamNameLabel teamNameLabel ${allRoundsOrder
            ?.map((_: any, idx: number) => `event${idx + 1}`)
            .join(' ')} leanLabel"
            "homeTeamIndicator  homeTeamName homeTeamName ${allRoundsOrder
              ?.map((_: any, mId: number) => {
                return `match${mId + 1}HomeScore`;
              })
              .flat()
              .join(' ')} leanHome"
          "awayTeamIndicator awayTeamName awayTeamName ${allRoundsOrder
            ?.map((_: any, mId: number) => {
              return `match${mId + 1}AwayScore`;
            })
            .flat()
            .join(' ')} leanAway"
           
          `,
          gridTemplateColumns: `6ch repeat(2, minmax(0, 8%))  repeat(${allRoundsOrder?.length}, minmax(0,12ch)) minmax(8ch, 16ch)`,
          gap: '1px',
          mb: 4,
        }}
      >
        {/* -------------- */}
        <GridCell
          sx={{
            gridArea: 'indicator',
            justifyContent: 'flex-start',
            backgroundColor: '#F8F9FA',
          }}
        ></GridCell>
        <GridCell sx={{ gridArea: 'homeTeamIndicator', justifyContent: 'flex-start' }}>
          <CellText>{v.titleCase('Home')}</CellText>
        </GridCell>
        <GridCell sx={{ gridArea: 'awayTeamIndicator', justifyContent: 'flex-start' }}>
          <CellText>{v.titleCase('Away')}</CellText>
        </GridCell>
        {/* -------------- */}

        {/* -------------- */}
        <GridCell
          sx={{
            gridArea: 'teamNameLabel',
            justifyContent: 'flex-start',
            backgroundColor: '#F8F9FA',
          }}
        >
          <ScoresLabel sx={{ color: '#02A2F2' }}>Team</ScoresLabel>
        </GridCell>
        <GridCell sx={{ gridArea: 'homeTeamName', justifyContent: 'flex-start' }}>
          <CellText id={`${matchName}-homeTeamName`} sx={{ color: '#02A2F2' }}>
            {v.titleCase(homeTeam?.name || '')}
          </CellText>
        </GridCell>
        <GridCell sx={{ gridArea: 'awayTeamName', justifyContent: 'flex-start' }}>
          <CellText id={`${matchName}-awayTeamName`} sx={{ color: '#02A2F2' }}>
            {v.titleCase(awayTeam?.name || '')}
          </CellText>
        </GridCell>

        {/* -------------- */}

        {/* -------------- */}
        {allRoundsOrder &&
          React.Children.toArray(
            allRoundsOrder.map((evtRound: any, idx: number) => (
              <GridCell
                sx={{
                  gridArea: `event${idx + 1}`,
                  //  padding: 0,
                  backgroundColor: '#F8F9FA',
                  textAlign: 'center',
                }}
              >
                <ScoresLabel
                  id={`${matchName}-homeTeam-${evtRound}-label`}
                  sx={evtRound === ComputedMASLRounds.Full ? { color: '#02A2F2' } : undefined}
                >{`${evtRound || '-'}`}</ScoresLabel>
              </GridCell>
            )),
          )}

        {allRoundsOrder &&
          React.Children.toArray(
            allRoundsOrder?.map((evtRound: any, mId: number) => (
              <>
                <GridCell
                  sx={{
                    gridArea: `match${mId + 1}HomeScore`,
                  }}
                >
                  <CellText
                    id={`${matchName}-homeTeam-${evtRound}-score`}
                    sx={evtRound === ComputedMASLRounds.Full ? { color: '#02A2F2' } : undefined}
                  >
                    {homeTeamAllRoundScores[evtRound] || '-'}
                  </CellText>
                </GridCell>

                <GridCell
                  sx={{
                    gridArea: `match${mId + 1}AwayScore`,
                  }}
                >
                  <CellText
                    id={`${matchName}-awayTeam-${evtRound}-score`}
                    sx={evtRound === ComputedMASLRounds.Full ? { color: '#02A2F2' } : undefined}
                  >
                    {awayTeamAllRoundScores[evtRound] || '-'}
                  </CellText>
                </GridCell>
              </>
            )),
          )}
        {/* -------------- */}

        {/* -------------- */}
        <GridCell sx={{ gridArea: 'leanLabel', backgroundColor: '#F8F9FA' }}>
          <ScoresLabel>Lean</ScoresLabel>
        </GridCell>
        <GridCell sx={{ gridArea: 'leanHome' }}>
          <DebugLabelDisplay row={homeTeamDtls} />
          <CounterButton
            // value={Number(COMMON_VALUE?.[teamTypeToKey.homeTeam]?.lean || 0)}
            incrId={`${matchName}-homeTeamLeanIncrBtn`}
            decId={`${matchName}-homeTeamLeanDecBtn`}
            valId={`${matchName}-homeTeamLeanVal`}
            value={homeTeamDtls?.lean || 0}
            changeHandler={(val: number) =>
              handleExternalUpdateOdds({ lean: val, isHomeTeam: true })
            }
            disabled={isDisabledMatch}
          />
        </GridCell>
        <GridCell sx={{ gridArea: 'leanAway' }}>
          <DebugLabelDisplay row={awayTeamDtls} />
          <CounterButton
            incrId={`${matchName}-awayTeamLeanIncrBtn`}
            decId={`${matchName}-awayTeamLeanDecBtn`}
            valId={`${matchName}-awayTeamLeanVal`}
            value={awayTeamDtls?.lean || 0}
            changeHandler={(val: number) =>
              handleExternalUpdateOdds({ lean: val, isHomeTeam: false })
            }
            disabled={isDisabledMatch}
          />
        </GridCell>

        {/* -------------- */}
      </Box>
    </>
  );
}
